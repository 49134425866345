import download from '@/lib/download';

/**
 * @file 权限
 */

interface Node {
    en_name: string;
    title: string;
    children?: Node[];

    [propName: string]: any;
}

export const tree: Node[] = [
    {
        en_name: 'home',
        title: '首页',
        children: [
            { en_name: 'statistics-amount', title: '金额笔数统计' },
            { en_name: 'statistics-coupon', title: '用券统计' },
            { en_name: 'bill_statistics_excel', title: '导出金额统计Excel数据' },
        ]
    },
    {
        en_name: 'district',
        title: '商圈管理',
        children: [
            { en_name: 'district-excel', title: '导出商圈Excel数据' },
            { en_name: 'district-create', title: '新增商圈' },
            { en_name: 'district-update', title: '编辑商圈' },
            { en_name: 'district-store', title: '商圈入驻列表' },
            { en_name: 'district-member', title: '商圈会员查询' }
        ]
    },
    {
        en_name: 'mall',
        title: '商场管理',
        children: [
            { en_name: 'mall-excel', title: '导出商场Excel数据' },
            { en_name: 'mall-create', title: '新增商场' },
            { en_name: 'mall-update', title: '编辑商场' },
            { en_name: 'mall-store', title: '商场入驻列表' },
            { en_name: 'mall-member', title: '商场会员查询' },
            { en_name: 'mall-address', title: '查看地址明文' }
        ]
    },
    {
        en_name: 'store',
        title: '商户管理',
        children: [
            { en_name: 'store-excel', title: '导出商户Excel数据' },
            { en_name: 'store-update', title: '编辑商户' },
            { en_name: 'store-order', title: '订单列表' },
            { en_name: 'store-order-excel', title: '导出订单Excel数据' },
            { en_name: 'store-activity', title: '营销数据' },
            { en_name: 'store-member', title: '商户会员查询' },
            { en_name: 'refund-batch-switch', title: '开通/关闭退款' },
            { en_name: 'sn_add', title: '店铺关联sn' },
            { en_name: 'sn_unbind', title: '店铺解绑sn' },
            { en_name: 'sn_list', title: '查询sn' },
            { en_name: 'staff_list', title: '员工一览' },
            { en_name: 'store_transfer', title: '转移微信号' },
            { en_name: 'store-address', title: '查看地址明文' },
            { en_name: 'store-smsswitch', title: '短信总开关切换' },
            { en_name: 'store-smsupdate', title: '短信控制' },
            { en_name: 'store-smstask', title: '短信账户发送记录' },
            { en_name: 'store-smsrecord', title: '短信账户购买记录' }
        ]
    },
    {
        en_name: 'order',
        title: '订单管理',
        children: [
            { en_name: 'order-excel', title: '导出订单Excel数据' },
            { en_name: 'order-sum', title: '金额求和' }
        ]
    },
    {
        en_name: 'user-manage',
        title: '用户管理',
        children: [
            {
                en_name: 'user', title: '平台用户列表',
                children: [
                    { en_name: 'user-create', title: '新增账号' },
                    { en_name: 'user-group', title: '分配角色' },
                    { en_name: 'user-delete', title: '删除账户' },
                ]
            },
            {
                en_name: 'role', title: '用户权限配置管理',
                children: [
                    { en_name: 'role-create', title: '新增角色' },
                    { en_name: 'role-permission', title: '分配权限' },
                    { en_name: 'role-delete', title: '删除角色' },
                ]
            },
            {
                en_name: 'userwhitelist', title: '白名单',
                children: [
                    { en_name: 'userwhitelist-create', title: '新增白名单' },
                    { en_name: 'userwhitelist-delete', title: '从白名单移除' },
                    { en_name: 'userwhitelist-batch-switch', title: '开通/关闭调试' },
                    { en_name: 'customer-delete', title: '删除用户' }
                ]
            },
            {
                en_name: 'user_customer_list', title: '惠码用户管理',
                children: [
                    { en_name: 'customcustomerer_list', title: '详情' },
                    { en_name: 'customer_excel', title: '导出惠码用户Excel数据' },
                    { en_name: 'customer-sms', title: '短信接收限制' },
                    // {en_name: 'user_customer_mobile', title: '查看手机明文'}
                ]
            },
        ]
    },
    {
        en_name: 'statistics-manage', title: '数据统计管理',
        children: [
            {
                en_name: 'marketing-data-list', title: '商户营销记录查询',
                children: [
                    { en_name: 'marketing_data_hami_excel', title: '导出商圈券Excel营销数据' },
                    { en_name: 'marketing_data_mallcoo_excel', title: '导出商场券Excel营销数据' },
                    { en_name: 'marketing_data_excel', title: '导出Excel营销数据' },
                ]
            },
        ],
    },
    {
        en_name: 'system-manage', title: '系统管理',
        children: [
            {
                en_name: 'log', title: '操作日志管理',
                children: [
                    { en_name: 'log_excel', title: '导出操作日志Excel数据' },
                    { en_name: 'log_delete', title: '删除操作日志' }
                ]
            },
        ],
    },
    {
        en_name: 'risk-control-manage',
        title: '风控黑名单管理',
        children: [
            { en_name: 'blacklist_create', title: '新增黑名单' },
            { en_name: 'blacklist_delete', title: '从黑名单移除' },
            // {en_name: 'query_blacklist_mobile', title: '查看黑名单手机明文'},
        ]
    },
    {
        en_name: 'Q_merchant_manage',
        title: 'Q码机具白名单管理',
        children: [
            { en_name: 'merchant_update', title: '编辑商户开关' },
            {
                en_name: 'task_list', title: '查看任务列表',
                children: [
                    { en_name: 'upload_excel', title: 'excel上传' },
                    { en_name: 'error_file_excel', title: '下载错误excel' },
                ]
            },
            { en_name: 'merchant_excel', title: '导出白名单列表' },
            { en_name: 'ready_update', title: '报备状态' }
        ]
    },
    { en_name: '', title: '查看手机明文' },
    { en_name: 'user-update', title: '修改密码' },
    {
        en_name: 'sms',
        title: '短信包配置管理',
        children: [
            { en_name: 'sms-create', title: '新增短信包' },
            { en_name: 'sms-update', title: '下架' },
            { en_name: 'sms-updsate', title: '上架' },
            { en_name: 'sms-excel', title: 'excel导出' }
        ]
    },
    {
        en_name: 'activity',
        title: '营销活动管理',
        children: [
            {
                en_name: 'open', title: '运营中',
                children: [
                    { en_name: 'open-stop', title: '暂停' },
                    { en_name: 'open-update', title: '编辑' },
                ]
            },
            {
                en_name: 'draft', title: '草稿箱',
                children: [
                    { en_name: 'draft-create', title: '新增活动' },
                    { en_name: 'draft-jihuo', title: '状态变更' },
                    { en_name: 'draft-stop', title: '明细下载' },
                ]
            },
            {
                en_name: 'stop', title: '已停止',
                children: [
                    { en_name: 'stop-open', title: '重启暂停批次' },
                ]
            },
            {
                en_name: 'detail-excel', title: '明细下载',
                children: [
                    { en_name: 'activity-detail', title: '活动明细' },
                    { en_name: 'sponsor-detail', title: '出资方明细' },
                    { en_name: 'activity-detail-excel', title: '活动明细下载' },
                    { en_name: 'sponsor-detail-excel', title: '出资方明细下载' },

                ]
            }, {
                en_name: 'hexiao', title: '核销文件上传管理',
                children: [
                    { en_name: 'hexiao_upload_excel', title: '上传' },
                    { en_name: 'download_excel', title: '下载' }
                ]
            },
        ]
    },
    {en_name: 'booth_code_download', title: '线下码申请下载'},
];

export function travel(nodes: Node[], fn: (node: Node) => void) {
    if (!nodes) {
        return;
    }
    nodes.forEach((node: any) => {
        fn(node);
        travel(node.children, fn);
    });
}

interface Privilege {
    id: number;
    english_name: string;
    name: string;
    parent: number | null;
};

interface PrivilegeNode {
    id: number;
    en_name: string;
    title: string;
    children: PrivilegeNode[];
}

interface DeepFindNode {
    children: DeepFindNode[];
}

/**
 * 树深度查询
 * @param nodes 根节点
 * @param fn 判断函数
 */
function deepFind<T extends DeepFindNode>(nodes: DeepFindNode[], fn: (a: T) => boolean): DeepFindNode | null {
    for (const node of nodes) {
        if (fn(node as T)) {
            return node;
        } else {
            const findNode = deepFind(node.children, fn);
            if (findNode) {
                return findNode;
            } else {
                continue;
            }
        }
    }
    return null;
}

/**
 * 根据后端的权限列表构造用于前端展示的权限树
 * @param privileges 后端返回的权限列表
 * @param privilegeNodes 当前构造出来的权限树
 * @param depth 函数调用深度
 * @param stackExceedDepth 触发异常的最大调用深度
 */
export function buildPrivilegeNodes(
    privileges: Privilege[], privilegeNodes: PrivilegeNode[] = [], depth = 0, stackExceedDepth?: number): PrivilegeNode[] {

    if (privileges.length === 0) {
        return privilegeNodes;
    }
    if (stackExceedDepth === undefined) {
        stackExceedDepth = (privileges.length + 1) * (privileges.length) / 2;
    }

    if (depth >= stackExceedDepth) {
        console.error('构造权限树失败, 请检查权限数据是否正确');
        return [];
    }

    const privilege = privileges[0];
    const restPrivilege = privileges.slice(1);
    const privilegeNode: PrivilegeNode = {
        id: privilege.id,
        en_name: privilege.english_name,
        title: privilege.name,
        children: []
    };
    if (privilege.parent === null) {
        privilegeNodes.push(privilegeNode);
        return buildPrivilegeNodes(restPrivilege, privilegeNodes, depth + 1, stackExceedDepth);
    } else {
        const parentPrivilegeNode = deepFind<PrivilegeNode>(privilegeNodes, (node) => node.id === privilege.parent);

        if (!parentPrivilegeNode) {
            return buildPrivilegeNodes(restPrivilege.concat(privilege), privilegeNodes, depth + 1, stackExceedDepth);
        } else {
            parentPrivilegeNode!.children.push(privilegeNode);
            return buildPrivilegeNodes(restPrivilege, privilegeNodes, depth + 1, stackExceedDepth);
        }
    }
}

// name字段作为权限标识需要与后端数据同步, 但层级和分组不必与后端相同, 此处的层级和分组只要前端认为合理即可。
const PRIVILEGE = {
    HOME: {
        STATISTICS_AMOUNT: {
            name: 'statistics-amount',
            text: '金额笔数统计'
        },
        STATISTICS_COUPON: {
            name: 'statistics-coupon',
            text: '用券统计'
        },
        STATISTICS_EXCEL: {
            name: 'bill_statistics_excel',
            text: '导出金额统计Excel数据'
        }
    },

    BIZ_DISTRICT: {
        LIST: {
            name: 'district',
            text: '商圈管理'
        }, // 查看商圈列表
        EXCEL: {
            name: 'district-excel',
            text: '导出商圈Excel数据'
        }, // 导出商圈列表,
        CREATE: {
            name: 'district-create',
            text: '新增商圈'
        }, // 新增商圈,
        UPDATE: {
            name: 'district-update',
            text: '编辑商圈'
        }, // 编辑商圈
        STORE: {
            name: 'district-store',
            text: '商圈入驻列表'
        }, // 入驻商户列表
        MEMBER: {
            name: 'district-member',
            text: '商圈会员查询'
        } // 会员列表
    },

    MALL: {
        LIST: {
            name: 'mall',
            text: '商场管理'
        },
        EXCEL: {
            name: 'mall-excel',
            text: '导出商场Excel数据'
        },
        CREATE: {
            name: 'mall-create',
            text: '新增商场'
        },
        UPDATE: {
            name: 'mall-update',
            text: '编辑商场'
        }, // 编辑商场
        STORE: {
            name: 'mall-store',
            text: '商场入驻列表'
        }, // 入驻商户列表
        MEMBER: {
            name: 'mall-member',
            text: '商场会员查询'
        }, // 会员列表
        ADDRESS: {
            name: 'mall-address',
            text: '查看地址明文'
        }
    },

    STORE: {
        LIST: {
            name: 'store',
            text: '商户管理'
        },
        EXCEL: {
            name: 'store-excel',
            text: '导出商户Excel数据'
        },
        UPDATE: {
            name: 'store-update',
            text: '编辑商户'
        },
        ORDER: {
            name: 'store-order',
            text: '店铺流水'
        },
        ORDER_EXCEL: {
            name: 'store-order-excel',
            text: '导出店铺流水Excel数据'
        },
        ACTIVITY: {
            name: 'store-activity',
            text: '营销数据'
        },
        MEMBER: {
            name: 'store-member',
            text: '商户会员查询'
        },
        ADDRESS: {
            name: 'store-address',
            text: '查看地址明文'
        },
        REFUND_SWITCH: {
            name: 'refund-batch-switch',
            text: '开启/关闭退款'
        },
        TRANSFER: {
            name: 'store_transfer',
            text: '转移微信号'
        },
        BIND_SN: {
            name: 'sn_add',
            text: '店铺关联SN'
        },
        UNBIND_SN: {
            name: 'sn_unbind',
            text: '店铺解绑SN'
        },
        SN_LIST: {
            name: 'sn_list',
            text: '查询sn'
        },
        STAFF_LIST: {
            name: 'staff_list',
            text: '员工一览'
        },
        SMS_SWITCH: {
            name: 'store-smsswitch',
            text: '短信总开关切换'
        },
        DAILY_LIMIT: {
            name: 'store-smsupdate',
            text: '短信功能控制'
        },
        STORE_SMSTASK: {
            name: 'store-smstask',
            text: '短信账户发送记录'
        },
        STORE_SMSRECORD: {
            name: 'store-smsrecord',
            text: '短信账户购买'
        }
    },
    SHOP_WHITE_LIST: {
        LIST: {
            name: 'store_open_list',
            text: '商铺白名单管理'
        },
        ADD: {
            name: 'store_open_create',
            text: '添加白名单'
        },
        DELETE: {
            name: 'store_open_delete',
            text: '从白名单删除'
        },
        SEE_MOBILE: {
            name: 'store_open_mobile',
            text: '查看手机明文'
        }
    },

    ORDER: {
        LIST: {
            name: 'order',
            text: '订单管理'
        }, // 查看订单列表
        EXCEL: {
            name: 'order-excel',
            text: '导出订单Excel数据'
        }, // 导出订单列表
        SUM: {
            name: 'order-sum',
            text: '金额求和'
        }
    },

    USER: {
        TAB: {
            name: 'user',
            text: '平台用户列表'
        },
        CREATE: {
            name: 'user-create',
            text: '新增账号'
        },
        GROUP: {
            name: 'user-group',
            text: '角色变更'
        },
        DELETE: {
            name: 'user-delete',
            text: '删除账号'
        }
    },

    ROLE: {
        TAB: {
            name: 'role',
            text: '用户权限配置管理'
        },
        CREATE: {
            name: 'role-create',
            text: '新增角色'
        },
        PERMISSION: {
            name: 'role-permission',
            text: '分配权限'
        },
        DELETE: {
            name: 'role-delete',
            text: '删除角色'
        }
    },

    WHITELIST: {
        TAB: {
            name: 'userwhitelist',
            text: '白名单'
        },
        CREATE: {
            name: 'userwhitelist-create',
            text: '新增白名单'
        },
        CUSTOMER_DELETE: {
            name: 'customer-delete',
            text: '删除用户'
        },
        WHITELIST_DELETE: {
            name: 'userwhitelist-delete',
            text: '从白名单移除'
        },
        DEBUG_SWITCH: {
            name: 'userwhitelist-batch-switch',
            text: '开通/关闭调试'
        }
    },

    CUSTOMER: {
        TAB: {
            name: 'user_customer_list',
            text: '惠码用户管理'
        },
        EXCEL: {
            name: 'customer_excel',
            text: '导出惠码用户Excel数据'
        },
        DETAIL: {
            name: 'customer_list',
            text: '查看惠码用户详情'
        },
        SMS_UPDATE: {
            name: 'customer-sms',
            text: '短信接收限制'
        }
        // PHONE: {
        //     name: 'user_customer_mobile',
        //     text: '查看惠码用户手机号'
        // }
    },

    MARKETINGDATA: {
        TAB: {
            name: 'marketing-data-list',
            text: '商户营销数据查询'
        },
        HAMI_EXCEL: {
            name: 'marketing_data_hami_excel',
            text: '导出商圈券Excel营销数据'
        },
        MALLCOO_EXCEL: {
            name: 'marketing_data_mallcoo_excel',
            text: '导出商场券Excel营销数据'
        },
        EXCEL: {
            name: 'marketing_data_excel',
            text: '导出Excel营销数据'
        }
    },

    USER_MOBILE: {
        name: 'user-mobile',
        text: '查看手机明文'
    },

    USER_UPDATE_PASSWORD: {
        name: 'user-update',
        text: '修改密码'
    },

    OPERATE_LOG: {
        LIST: {
            name: 'log',
            text: '操作日志管理'
        },
        EXCEL: {
            name: 'log_excel',
            text: '导出操作日志Excel数据'
        },
        DELETE: {
            name: 'log_delete',
            text: '删除操作日志'
        }
    },

    BLACKLIST: {
        LIST: {
            name: 'risk-control-manage',
            text: '黑名单查询'
        },
        CREATE: {
            name: 'blacklist_create',
            text: '新增黑名单'
        },
        DELETE: {
            name: 'blacklist_delete',
            text: '从黑名单移除'
        }
        // MOBILE: {
        //     name: 'query_blacklist_mobile',
        //     text: '查看黑名单手机明文'
        // }
    },

    Q_WHITELIST: {
        LIST: {
            name: 'Q_merchant_manage',
            text: 'Q码机具白名单管理'
        },
        UPDATE_STATUS: {
            name: 'merchant_update',
            text: '编辑商户开关'
        },
        TASK_LIST: {
            name: 'task_list',
            text: '查看任务列表'
        },
        EXPORT_MERCHANT_EXCEL: {
            name: 'merchant_excel',
            text: '导出白名单列表'
        },
        UPLOAD_EXCEL: {
            name: 'upload_excel',
            text: 'excel上传'
        },
        EXPORT_TASK_ERROR_EXCEL: {
            name: 'error_file_excel',
            text: '下载错误excel'
        },
        READY_UPDATE: {
            name: 'ready_update',
            text: '报备状态'
        }
    },

    AD: {
        MANAGE: {
            name: 'advertisement-manage',
            text: '广告管理'
        },
        LANDING_PAGE: {
            name: 'ad-landing-page-create',
            text: '地址生成'
        },
        CREATE: {
            name: 'ad-create',
            text: '新增'
        },
        UPDATE: {
            name: 'ad-update',
            text: '编辑'
        },
        BULK_OFF: {
            name: 'ad-bulk-off',
            text: '一键下架'
        },
        BULK_DELETE: {
            name: 'ad-bulk-delete',
            text: '删除'
        },
        EXPORT: {
            name: 'ad-export',
            text: 'excel导出'
        },
        WHITELIST: {
            name: 'ad-whitelist',
            text: '白名单'
        }
    },
    SM: {
        CONFIGURE: {
            name: 'sms',
            text: '短信包配置管理'
        },
        CREATE: {
            name: 'sms-create',
            text: '新增'
        },
        BULK_OFF: {
            name: 'sms-update',
            text: '下架'
        },
        BULK_ON: {
            name: 'sms-update',
            text: '上架'
        },
        EXPORT: {
            name: 'sms-excel',
            text: 'excel导出'
        },
    },
    ACTIVITIES_OPEN: {
        TAB: {
            name: 'open',
            text: '运营中'
        },
        STOP: {
            name: 'open-stop',
            text: '暂停'
        },
        UPDATE: {
            name: 'open-update',
            text: '编辑'
        }
    },
    MANAGER_WECHAT:{
        TAB: {
            name: 'wechat',
            text: '微信'
        },
    },
    MANAGER_UNION:{
        TAB: {
            name: 'union',
            text: '银联'
        },
    },
    ACTIVITIES_DRAFT: {
        TAB: {
            name: 'draft',
            text: '草稿箱'
        },
        CREATE: {
            name: 'draft-create',
            text: '新增活动'
        },
        JIHUO: {
            name: 'draft-jihuo',
            text: '活动激活'
        },
        STOP: {
            name: 'draft-stop',
            text: '终止活动'
        }
    },
    STATEMENT: {
        OPERATE: {
            name: "statement-operate",
            text: '对账单手工操作'
        },
        INFO: {
            name: "statement-info",
            text: '对账单表操作'
        },
        GW: {
            name: 'invokingGW',
            text: '触发营销网关对账'
        },
        CREATE: {
            name: 'statement-recon',
            text: '对账'
        },
        RGTZTC: {
            name: 'statement-rgtztc',
            text: '人工调整头寸'
        },

    },
    ACTIVITIES_STOP: {
        TAB: {
            name: 'stop',
            text: '已停止'
        },
        OPEN: {
            name: 'stop-open',
            text: '重启暂停批次'
        }
    },
    DETAIL_DOWNLOAD: {
        ACTIVITY_DETAIL: {
            name: 'activity-detail',
            text: '活动明细'
        },
        SPONSOR_DETAIL: {
            name: 'sponsor-detail',
            text: '出资方明细'
        },
        ACTIVITY_DETAIL_EXCEL: {
            name: 'activity-detail-excel',
            text: '活动明细下载'
        },
        SPONSOR_DETAIL_EXCEL: {
            name: 'sponsor-detail-excel',
            text: '出资方明细下载'
        }
    },
    HEXIAO: {
        CONFIGURE: {
            name: 'hexiao',
            text: '核销管理'
        },
        UPDATE_EXCEL: {
            name: 'hexiao_upload_excel',
            text: '上传'
        },
        DOWNLOAD_EXCEL: {
            name: 'download_excel',
            text: '下载'
        }
    },
    UNION_ACTIVITIES_OPEN: {
        TAB: {
            name: 'open-union',
            text: '运营中'
        },
        STOP: {
            name: 'open-stop-union',
            text: '暂停'
        },
        UPDATE: {
            name: 'open-update-union',
            text: '编辑'
        }
    },
    UNION_ACTIVITIES_DRAFT: {
        TAB: {
            name: 'draft-union',
            text: '草稿箱'
        },
        CREATE: {
            name: 'draft-create-union',
            text: '新增活动'
        },
        JIHUO: {
            name: 'draft-jihuo-union',
            text: '活动激活'
        },
        STOP: {
            name: 'draft-stop-union',
            text: '终止活动'
        }
    },
    UNION_ACTIVITIES_STOP: {
        TAB: {
            name: 'stop-union',
            text: '已停止'
        },
        OPEN: {
            name: 'stop-open-union',
            text: '重启暂停批次'
        }
    },
    UNION_DETAIL_DOWNLOAD: {
        ACTIVITY_DETAIL:{
            name:'activity-detail-union',
            text:'活动明细'
        },
        SPONSOR_DETAIL:{
            name:'sponsor-detail-union',
            text:'出资方明细'
        },
        ACTIVITY_DETAIL_EXCEL:{
            name:'activity-detail-excel-union',
            text:'活动明细下载'
        },
        SPONSOR_DETAIL_EXCEL:{
            name:'sponsor-detail-excel-union',
            text:'出资方明细下载'
        }
    },
    UNION_HEXIAO:{
        CONFIGURE: {
            name: 'hexiao-union',
            text: '核销管理'
        },
        UPDATE_EXCEL:{
            name:'hexiao_upload_excel-union',
            text:'上传'
        },
        DOWNLOAD_EXCEL:{
            name:'download_excel-union',
            text:'下载'
        }
    },
    BOOTHCODE:{
        CODE_DOWNLOAD:{
            name:'booth_code_download',
            text:'下载'
        }
    },
    ACTIVITIES_UNIONB2C: {
        TAB: {
            name: 'unionb2c',
            text: '银联B2C全场'
        },
        CREATE: {
            name: 'unionb2c-create',
            text: '新增活动'
        },
        EDIT: {
            name: 'unionb2c-edit',
            text: '活动修改'
        },
        STOP: {
            name: 'unionb2c-stop',
            text: '终止活动'
        }
    },
    ACTIVITIES_CITIC: {
        TAB: {
            name: 'citic',
            text: '微信代金券活动'
        },
        CREATE: {
            name: 'citic-create',
            text: '新增活动'
        },
        EDIT: {
            name: 'citic-edit',
            text: '活动修改'
        },
        STOP: {
            name: 'citic-stop',
            text: '终止活动'
        },
        DETAIL: {
            name: 'citic-detail',
            text: '查询活动详情'
        }
    },
    ACTIVITIES_UNIONCOUPON: {
        TAB: {
            name: 'union-coupon',
            text: '银联代金券活动'
        },
        CREATE: {
            name: 'union-coupon-create',
            text: '新增活动'
        },
        EDIT: {
            name: 'union-coupon-edit',
            text: '活动修改'
        },
        STOP: {
            name: 'union-coupon-stop',
            text: '终止活动'
        },
        DETAIL: {
            name: 'union-coupon-detail',
            text: '查询活动详情'
        }
    },
    
    GRAY: {
        LIST: {
            name: 'gray_manage',
            text: '重构灰度切换管理'
        },
        UPDATE_STATUS: {
            name: 'gray_merchant_update',
            text: '商户开关'
        },
        UPLOAD_EXCEL: {
            name: 'gray_upload_excel',
            text: 'excel上传'
        },
        DELETE_STATUS:{
            name: 'gray_delete_excel',
            text: '删除'
        }
    },
    DISTRICT_CODE: {
        LIST: {
            name: 'district_code_manage',
            text: '地区码维护管理'
        },
        UPDATE: {
            name: 'district_code_update',
            text: '修改'
        },
        UPLOAD_EXCEL: {
            name: 'district_code_upload_excel',
            text: '文件上传'
        }
    },
    PAY_EXCHANGE: {
        LIST: {
            name: 'transfer_merchant_list',
            text: '支付转发管理'
        },
        UPDATE_STATUS: {
            name: 'transfer_merchant_switch',
            text: '交易切换'
        },
        UPLOAD_EXCEL: {
            name: 'transfer_merchant_upload',
            text: '文件上传'
        },
        DELETE_STATUS: {
            name: 'transfer_merchant_delete',
            text: '删除'
        },
        DOWNLOAD: {
            name: 'transfer_merchant_download',
            text: '模板下载'
        }
    },
    SHTMANAGE: {
        LIST: {
            name: 'sht_shopManage',
            text: '商户通商户推送管理'
        },
        UPDATE_STATUS: {
            name: 'sht_updateStatus',
            text: '推送状态变更'
        }
    },
    ACTIVITYSIGNUP: {
        LIST: {
            name: 'signUpManage',
            text: '活动报名管理'
        },
        UPDATE: {
            name: 'signUpUpdate',
            text: '活动报名修改'
        },
        ADD: {
            name: 'signUpAdd',
            text: '新增活动报名'
        },
        UPDATE_STATUS: {
            name: 'signUpUpdateStatus',
            text: '活动报名修改状态'
        },
        URl_LIST:{
            name: 'signUpUrlList',
            text: '活动报名地址管理'
        }
    },
    ACTIVITY_INSPECTIOON: {
        LIST: {
            name: 'inspectionManage',
            text: '商户审核管理'
        },
        DOWNLOAD_EXCEL: {
            name: 'inspectionExcel',
            text: '文件下载'
        },
    },
    SUBJECT: {
        LIST: {
            name: 'subjectManage',
            text: '主题活动管理'
        },
        UPDATE: {
            name: 'subjectUpdate',
            text: '主题活动修改'
        },
        ADD: {
            name: 'subjectAdd',
            text: '主题活动新增'
        }
    },
    ACCOUNT: {
        LIST: {
            name: 'accountManage',
            text: '机构头寸管理'
        },
        UPDATE: {
            name: 'accountUpdate',
            text: '机构头寸修改'
        },
        ADD: {
            name: 'accountAdd',
            text: '机构头寸新增'
        },
        DELETE: {
            name: 'accountDelete',
            text: '机构头寸新增'
        }
    },
    BANK_POLICY: {
        TAB: {
            name: 'bank_policy_tab',
            text: '政策列表'
        },
        CREATE: {
            name: 'bank_policy_create',
            text: '新增政策'
        },
        EDIT: {
            name: 'bank_policy_edit',
            text: '政策修改'
        },
        UPDATE_STATUS: {
            name: 'bank_policy_status',
            text: '状态变更'
        },
        DETAIL: {
            name: 'bank_policy_detail',
            text: '查询详情'
        }
    },
    BANK_ACTIVITY: {
        TAB: {
            name: 'bank_activity_tab',
            text: '活动列表'
        },
        CREATE: {
            name: 'bank_activity_create',
            text: '新增活动'
        },
        EDIT: {
            name: 'bank_activity_edit',
            text: '活动修改'
        },
        RULE: {
            name: 'bank_activity_rule',
            text: '活动规则'
        },
        DETAIL: {
            name: 'bank_activity_detail',
            text: '查询活动详情'
        },
        STATUS: {
            name: 'bank_activity_updateStatus',
            text: '失效'
        }
    },
    BANK_SIGNUP_AUTIO: {
        TAB: {
            name: 'bank_autio_tab',
            text: '报名列表'
        },
        UPLOAD: {
            name: 'bank_autio_upload',
            text: '审核导入'
        },
        UPLOAD_APPLY: {
            name: 'bank_autio_upload_apply',
            text: '批量报名上传'
        },
        DOWNLOAD: {
            name: 'bank_autio_download',
            text: '报名审核列表下载'
        }
    },
    BANK_SUBSIDY_DETAIL: {
        TAB: {
            name: 'bank_subsidyDetail_tab',
            text: '补贴明细列表'
        },
        DOWNLOAD: {
            name: 'bank_subsidyDetail_download',
            text: '下载'
        }
    },
    MER_SUBSIDY_ACT: {
        TAB: {
            name: 'mer_subsidy_tab',
            text: '活动列表'
        },
        CREATE: {
            name: 'mer_subsidy_create',
            text: '新增活动'
        },
        EDIT: {
            name: 'mer_subsidy_edit',
            text: '活动修改'
        },
        RULE: {
            name: 'mer_subsidy_rule',
            text: '商户规则'
        },
        DETAIL: {
            name: 'mer_subsidy_detail',
            text: '查询活动详情'
        }
    },
    MER_SUBSIDY_RULE: {
        TAB: {
            name: 'mer_subsidy_rule_tab',
            text: '规则列表'
        },
        CREATE: {
            name: 'mer_subsidy_rule_create',
            text: '新增规则'
        },
        EDIT: {
            name: 'mer_subsidy_rule_edit',
            text: '规则修改'
        },
        STATUS: {
            name: 'mer_subsidy_rule_status',
            text: '规则状态变更'
        },
        DETAIL: {
            name: 'mer_subsidy_rule_detail',
            text: '查询规则详情'
        }
    },
    BANK_ACTIVITY_MER: {
        TAB: {
            name: 'act_mer_tab',
            text: '活动商户列表'
        },
        CREATE: {
            name: 'act_mer_create',
            text: '新增'
        },
        EDIT: {
            name: 'act_mer_edit',
            text: '修改'
        },
        STATUS: {
            name: 'act_mer_status',
            text: '状态变更'
        },
        DETAIL: {
            name: 'act_mer_detail',
            text: '活动商户详情'
        },
        BANCH_CREATE: {
            name: 'act_mer_banch_create',
            text: '批量新增'
        },
        BANCH_EDIT: {
            name: 'act_mer_banch_edit',
            text: '批量修改'
        }
    },
};

export default PRIVILEGE;
