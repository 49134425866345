
































































import {Modal} from 'ant-design-vue';
import {Component, Prop,Vue,Watch} from 'vue-property-decorator';
import {CreateParams} from './services';
import {mapState} from 'vuex';
import config from '@/config';
import moment from 'moment';
import VueUeditorWrap from 'vue-ueditor-wrap';
interface SearchForm {
    form: any;
    hasLogo: boolean;
}

@Component({
    components: {
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.accountActivitySearchPage.loading,
            formData(state: any) {
                return state.accountActivitySearchPage.data && this.formatFormData(state.accountActivitySearchPage.data);
            }
        })
    }
})
class SearchForm extends Vue {
    @Prop()
    public remark;
    @Prop()
    public expenseAccount;
    @Prop()
    public name;
    public data() {
        return {
            config,
            form: this.$form.createForm(this)
        };
    }
    public created() {
        
    }
    
    public handleCreate(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                Modal.confirm({
                    title: '提示',
                    content: '是否确认提交?',
                    onOk: () => {
                        this.$store.dispatch('accountActivitySearchPage/editAct',values);
                    },
                    onCancel() {
                        // do nothing
                    }
                });
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    @Watch('$store.state.accountActivitySearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            this.$message.success('提交成功');
            this.$emit('onCancel');
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                this.$emit('onClose');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    
    private formatFormData(rawData: any) {
        return {
            ...rawData
        }
    }
}

export default SearchForm;
