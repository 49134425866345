import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import bizDistrictSearchPage from './pages/Console/BizDistrict/SearchPage/newstore';
import bizDistrictCreatePage from './pages/Console/BizDistrict/CreatePage/store';
import bizDistrictEditPage from './pages/Console/BizDistrict/EditPage/store';
import mallSearchPage from './pages/Console/Mall/SearchPage/newstore';
import mallCreatePage from './pages/Console/Mall/CreatePage/store';
import mallEditPage from './pages/Console/Mall/EditPage/store';
import shopSearchPage from './pages/Console/Shop/SearchPage/newstore';
import shopEditPage from './pages/Console/Shop/EditPage/store';
import orderSearchPage from './pages/Console/Order/SearchPage/newstore';
import userSearchPage from './pages/Console/Account/User/SearchPage/newstore';
import roleSearchPage from './pages/Console/Account/Role/SearchPage/newstore';
import whitelistSearchPage from './pages/Console/Account/Whitelist/SearchPage/newstore';
import blacklistSearchPage from './pages/Console/Blacklist/SearchPage/newstore';
import customerSearchPage from './pages/Console/Account/Customer/SearchPage/store';
import newbieSearchPage from './pages/Console/Marketing/Newbie/SearchPage/store';
import luckyPacketSearchPage from './pages/Console/Marketing/LuckyPacket/SearchPage/store';
import orientationSearchPage from './pages/Console/Marketing/Orientation/SearchPage/store';
import mallDataSearchPage from './pages/Console/Marketing/Mall/SearchPage/store';
import otherSearchPage from './pages/Console/Marketing/Other/SearchPage/store';
import common from './common/store';
import login from './pages/Login/store';
import operateLogSearchPage from './pages/Console/System/OperateLog/SearchPage/newstore';
import adCreatePage from './pages/Console/Advertisement/CreatePage/store';
import adEditPage from './pages/Console/Advertisement/EditPage/store';
import activitiesOpenSearchPage from './pages/Console/Activities/ActivityManage/Open/SearchPage/newstore';
import activitiesStopSearchPage from './pages/Console/Activities/ActivityManage/Stop/SearchPage/newstore';
import activitiesDraftSearchPage from './pages/Console/Activities/ActivityManage/Draft/SearchPage/newstore';
import activityDetailSearchPage from './pages/Console/Activities/ActivityManage/DetailDownload/ActivityDetail/SearchPage/newstore';
import activityDetailSearchPageUnion from './pages/Console/Activities/ActivityManage/DetailDownload/ActivityDetailUnion/SearchPage/newstore';
import activityManage from './pages/Console/Activities/ActivityManage/store';
import sponsorDetailSearchPage from './pages/Console/Activities/ActivityManage/DetailDownload/SponsorDetail/SearchPage/newstore';
import hexiaoManageSearchPage from './pages/Console/Activities/Hexiao/SearchPage/newstore';
import hexiaoUnionManageSearchPage from './pages/Console/Activities/Hexiao/SearchUnionPage/newstore';
import StatementInfoPage from './pages/Console/Activities/Statement/Info/newstore';
import StatementOperatePage from './pages/Console/Activities/Statement/Operate/newstore';
import activityCreatePage from './pages/Console/Activities/ActivityManage/Draft/CreatePage/store';
import activityCreateUnionPage from './pages/Console/Activities/ActivityManage/Draft/CreatePageUnion/store';
import activityEditPage from './pages/Console/Activities/ActivityManage/Open/EditPage/store';
import activityEditPageUnion from './pages/Console/Activities/ActivityManage/Open/EditPageUnion/store';
import unionb2cSearchPage from './pages/Console/Activities/ActivityManage/unionb2c/SearchPage/newstore';
import unionCouponSearchPage from './pages/Console/Activities/ActivityManage/unionCoupon/SearchPage/newstore';
import zhongxinSearchPage from './pages/Console/Activities/ActivityManage/zhongxin/SearchPage/newstore';
import buyRecordSearchPage from './pages/Console/Shop/SearchPage/SmsAccount/BuyRecord/SearchPage/store';
import sendTaskSearchPage from './pages/Console/Shop/SearchPage/SmsAccount/SendTask/SearchPage/store';
import activitySignUpPage from './pages/Console/Activities/SignUp/config/SearchPage/newstore';
import activityInspectionPage from './pages/Console/Activities/SignUp/inspection/SearchPage/newstore';
import subjectActivitySearchPage from './pages/Console/Activities/subject/SearchPage/newstore';
import accountActivitySearchPage from './pages/Console/Activities/account/SearchPage/newstore';
import policyActivitySearchPage from './pages/Console/BankActivities/policy/SearchPage/newstore';
import actManageSearchPage from './pages/Console/BankActivities/actManage/SearchPage/newstore';
import actShopLimitSearchPage from './pages/Console/BankActivities/actManage/RulePage/newstore';
import merSubsidyActSearchPage from './pages/Console/Activities/ActivityManage/merSubsidyAct/SearchPage/newstore';
import merSubsidyRuleSearchPage from './pages/Console/BankActivities/ruleMange/merSubsidyRule/SearchPage/newstore';
Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store<{
    bizDistrictSearchPage: any,
    bizDistrictCreatePage: any,
    bizDistrictEditPage: any,
    mallSearchPage: any,
    mallEditPage: any,
    mallCreatePage: any,
    shopSearchPage: any,
    shopEditPage: any,
    orderSearchPage: any,
    userSearchPage: any,
    roleSearchPage: any,
    whitelistSearchPage: any,
    customerSearchPage: any,
    newbieSearchPage: any,
    luckyPacketSearchPage: any,
    orientationSearchPage: any,
    mallDataSearchPage: any,
    otherSearchPage: any,
    login: any,
    common: any,
    operateLogSearchPage: any,
    adCreatePage: any,
    adEditPage: any,
    activitiesDraftSearchPage: any,
    activitiesStopSearchPage: any,
    activitiesOpenSearchPage: any,
    activityDetailSearchPage: any,
    activityDetailSearchPageUnion: any,
    activityManage: any,
    sponsorDetailSearchPage: any,
    hexiaoManageSearchPage: any,
    hexiaoUnionManageSearchPage: any,
    StatementInfoPage: any,
    StatementOperatePage: any,
    activityCreatePage: any,
    activityCreateUnionPage: any,
    activityEditPage: any,
    activityEditPageUnion: any,
    unionb2cSearchPage: any,
    zhongxinSearchPage: any,
    unionCouponSearchPage:any,
    activitySignUpPage:any,
    activityInspectionPage:any,
    subjectActivitySearchPage:any,
    accountActivitySearchPage:any,
    policyActivitySearchPage:any,
    actManageSearchPage:any,
    actShopLimitSearchPage:any,
    merSubsidyActSearchPage:any,
    merSubsidyRuleSearchPage:any
}>({
    modules: {
        bizDistrictSearchPage,
        bizDistrictCreatePage,
        bizDistrictEditPage,
        mallSearchPage,
        mallEditPage,
        mallCreatePage,
        shopSearchPage,
        shopEditPage,
        orderSearchPage,
        userSearchPage,
        roleSearchPage,
        whitelistSearchPage,
        blacklistSearchPage,
        customerSearchPage,
        newbieSearchPage,
        luckyPacketSearchPage,
        orientationSearchPage,
        mallDataSearchPage,
        otherSearchPage,
        login,
        common,
        operateLogSearchPage,
        adCreatePage,
        adEditPage,
        activitiesDraftSearchPage,
        activitiesStopSearchPage,
        activitiesOpenSearchPage,
        activityDetailSearchPage,
        activityDetailSearchPageUnion,
        activityManage,
        sponsorDetailSearchPage,
        hexiaoManageSearchPage,
        hexiaoUnionManageSearchPage,
        StatementInfoPage,
        StatementOperatePage,
        activityCreatePage,
        activityCreateUnionPage,
        activityEditPage,
        activityEditPageUnion,
        unionb2cSearchPage,
        zhongxinSearchPage,
        sendTaskSearchPage,
        buyRecordSearchPage,
        unionCouponSearchPage,
        activitySignUpPage,
        activityInspectionPage,
        subjectActivitySearchPage,
        accountActivitySearchPage,
        policyActivitySearchPage,
        actManageSearchPage,
        actShopLimitSearchPage,
        merSubsidyActSearchPage,
        merSubsidyRuleSearchPage
    },
    plugins: debug ? [createLogger()] : []
});
