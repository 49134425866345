


































































































import {Modal} from 'ant-design-vue';
import {Component, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.activityMerSearchPage.loading,
                formData(state: any) {
                    return state.activityMerSearchPage.data && this.formatFormData(state.activityMerSearchPage.data);
                },
                
            }),
            
        }
    })
    class createForm extends Vue {
        public districtCodeList=[];
        public awardRuleData= [];
        public activityId:any ;
        public ruleId:any;
        form: any;
        public data() {
            return {
                config,
                form: this.$form.createForm(this)
            };
        }
        public created() {
            
        }
        
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'activityMerSearchPage/editAct',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.activityMerSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                this.$message.success('提交成功');
                this.$emit('onCancel');
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                    this.$emit('onCancel');
                },
                onCancel() {
                    // do nothing
                }
            });
        }
       
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            
            return {
                ...formData,
                operateType: '2',
                activityId:this.activityId,
                ruleId:this.ruleId
            };
        }

        
        private formatFormData(rawData: any) {
            this.activityId = rawData.activityId;
            this.ruleId = rawData.ruleId;
            return {
                ...rawData,
            }
        }
    }

    export default createForm;
