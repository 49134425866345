



















import DetailForm from './DetailForm.vue';
import {Component,Prop, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
        DetailForm
    }
})
export default class index extends Vue {
    public PRIVILEGE = PRIVILEGE;
    @Prop()
    public title!: string | number;
    @Prop()
    public activityInfo !: any;
    @Prop()
    public paytype !: string;
    public created() {
    }

    @Watch('activityInfo') 
    public async initQueryData(error: any) {
        
        if(!this.activityInfo){
            return; 
        }
        await this.$store.dispatch('merSubsidyRuleSearchPage/initData', {
            activityId: this.activityInfo.activityId,
            ruleId:this.activityInfo.ruleId
        });
    }

    @Watch('$store.state.merSubsidyRuleSearchPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }

    public onClose() {
        this.$emit('close');
    }
}
