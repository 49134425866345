var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-form-item-wrapper" },
    [
      [
        _c(
          "a-form",
          { attrs: { form: _vm.form } },
          [
            _c(
              "a-form-item",
              _vm._b({}, "a-form-item", _vm.formItemLayoutWithOutLabel, false),
              [
                _c(
                  "a-button",
                  {
                    staticStyle: { width: "16%" },
                    attrs: {
                      disabled: _vm.isDisabledFlag == "detail",
                      type: "dashed"
                    },
                    on: { click: _vm.add }
                  },
                  [
                    _c("a-icon", { attrs: { type: "plus" } }),
                    _vm._v(" 新增\n          ")
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.form.getFieldValue("keys"), function(k, index) {
              return _c(
                "a-form-item",
                { key: "data" + index, attrs: { keys: k, required: false } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "custom-form-item-wrapper",
                      staticStyle: { width: "80%", display: "inline-block" }
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: "交易金额", required: "" } },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "36%",
                              "margin-right": "15px"
                            },
                            attrs: { disabled: _vm.isDisabledFlag == "detail" },
                            model: {
                              value: _vm.ruleListData[index].minAmt,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleListData[index], "minAmt", $$v)
                              },
                              expression: "ruleListData[index].minAmt"
                            }
                          }),
                          _vm._v("\n            -\n            "),
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "15px",
                              width: "36%"
                            },
                            attrs: { disabled: _vm.isDisabledFlag == "detail" },
                            model: {
                              value: _vm.ruleListData[index].maxAmt,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleListData[index], "maxAmt", $$v)
                              },
                              expression: "ruleListData[index].maxAmt"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "交易费率(%)" } },
                        [
                          _c("a-input", {
                            staticStyle: {
                              width: "37%",
                              "margin-right": "15px"
                            },
                            attrs: { disabled: _vm.isDisabledFlag == "detail" },
                            model: {
                              value: _vm.ruleListData[index].minRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleListData[index],
                                  "minRate",
                                  $$v
                                )
                              },
                              expression: "ruleListData[index].minRate"
                            }
                          }),
                          _vm._v("\n            -\n            "),
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "15px",
                              width: "37%"
                            },
                            attrs: { disabled: _vm.isDisabledFlag == "detail" },
                            model: {
                              value: _vm.ruleListData[index].maxRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleListData[index],
                                  "maxRate",
                                  $$v
                                )
                              },
                              expression: "ruleListData[index].maxRate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "补贴类型", required: "" } },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: {
                                disabled:
                                  (_vm.isDisabledFlag == "detail" ||
                                    _vm.isDisabledFlag == "edit") &&
                                  _vm.ruleListData[index].falg != "new"
                              },
                              model: {
                                value: _vm.ruleListData[index].subsidyType,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleListData[index],
                                    "subsidyType",
                                    $$v
                                  )
                                },
                                expression: "ruleListData[index].subsidyType"
                              }
                            },
                            [
                              _c(
                                "a-radio",
                                { attrs: { value: "1", checked: true } },
                                [_vm._v("固定补贴后费率")]
                              ),
                              _c(
                                "a-radio",
                                { attrs: { value: "2", checked: true } },
                                [_vm._v("固定补贴比例")]
                              ),
                              _c(
                                "a-radio",
                                { attrs: { value: "3", checked: true } },
                                [_vm._v("固定金额补贴")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType == "1",
                              expression:
                                "ruleListData[index].subsidyType == '1'"
                            }
                          ],
                          attrs: { label: "优惠后费率(%)", required: "" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "16px",
                              width: "78%"
                            },
                            attrs: {
                              disabled:
                                (_vm.isDisabledFlag == "detail" ||
                                  _vm.isDisabledFlag == "edit") &&
                                _vm.ruleListData[index].falg != "new"
                            },
                            model: {
                              value: _vm.ruleListData[index].rate,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleListData[index], "rate", $$v)
                              },
                              expression: "ruleListData[index].rate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType == "2",
                              expression:
                                "ruleListData[index].subsidyType == '2'"
                            }
                          ],
                          attrs: { label: "补贴比例(%)", required: "" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "30px",
                              width: "75.5%"
                            },
                            attrs: {
                              disabled:
                                (_vm.isDisabledFlag == "detail" ||
                                  _vm.isDisabledFlag == "edit") &&
                                _vm.ruleListData[index].falg != "new"
                            },
                            model: {
                              value: _vm.ruleListData[index].ratio,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleListData[index], "ratio", $$v)
                              },
                              expression: "ruleListData[index].ratio"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType == "3",
                              expression:
                                "ruleListData[index].subsidyType == '3'"
                            }
                          ],
                          attrs: { label: "补贴金额", required: "" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "53px",
                              width: "71.5%"
                            },
                            attrs: {
                              disabled:
                                (_vm.isDisabledFlag == "detail" ||
                                  _vm.isDisabledFlag == "edit") &&
                                _vm.ruleListData[index].falg != "new"
                            },
                            model: {
                              value: _vm.ruleListData[index].transAmt,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleListData[index],
                                  "transAmt",
                                  $$v
                                )
                              },
                              expression: "ruleListData[index].transAmt"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.accModeType &&
                                _vm.ruleListData[index].subsidyType != "3",
                              expression:
                                "accModeType && ruleListData[index].subsidyType != '3'"
                            }
                          ],
                          attrs: { label: "实补比例(%)", required: "" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "32px",
                              width: "75%"
                            },
                            attrs: {
                              disabled:
                                (_vm.isDisabledFlag == "detail" ||
                                  _vm.isDisabledFlag == "edit") &&
                                _vm.ruleListData[index].falg != "new"
                            },
                            model: {
                              value: _vm.ruleListData[index].realSubRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleListData[index],
                                  "realSubRate",
                                  $$v
                                )
                              },
                              expression: "ruleListData[index].realSubRate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType != "3",
                              expression:
                                "ruleListData[index].subsidyType != '3'"
                            }
                          ],
                          attrs: { label: "单笔补贴最低金额" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "79%"
                            },
                            attrs: { disabled: _vm.isDisabledFlag == "detail" },
                            model: {
                              value: _vm.ruleListData[index].minSubsidyAmt,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleListData[index],
                                  "minSubsidyAmt",
                                  $$v
                                )
                              },
                              expression: "ruleListData[index].minSubsidyAmt"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType != "3",
                              expression:
                                "ruleListData[index].subsidyType != '3'"
                            }
                          ],
                          attrs: { label: "单笔补贴封顶金额" }
                        },
                        [
                          _c("a-input", {
                            staticStyle: {
                              "margin-left": "10px",
                              width: "79%"
                            },
                            attrs: { disabled: _vm.isDisabledFlag == "detail" },
                            model: {
                              value: _vm.ruleListData[index].maxSubsidyAmt,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.ruleListData[index],
                                  "maxSubsidyAmt",
                                  $$v
                                )
                              },
                              expression: "ruleListData[index].maxSubsidyAmt"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType != "1",
                              expression:
                                "ruleListData[index].subsidyType != '1'"
                            }
                          ],
                          attrs: { label: "补贴金额大于交易金额", required: "" }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: {
                                disabled: _vm.isDisabledFlag == "detail"
                              },
                              model: {
                                value: _vm.ruleListData[index].subAmtGtTrans,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleListData[index],
                                    "subAmtGtTrans",
                                    $$v
                                  )
                                },
                                expression: "ruleListData[index].subAmtGtTrans"
                              }
                            },
                            [
                              _c("a-radio", { attrs: { value: "1" } }, [
                                _vm._v("可以")
                              ]),
                              _c("a-radio", { attrs: { value: "2" } }, [
                                _vm._v("不可以")
                              ])
                            ],
                            1
                          ),
                          _c("br")
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleListData[index].subsidyType != "1",
                              expression:
                                "ruleListData[index].subsidyType != '1'"
                            }
                          ],
                          attrs: {
                            label: "补贴金额大于交易手续费",
                            required: ""
                          }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: {
                                disabled: _vm.isDisabledFlag == "detail"
                              },
                              model: {
                                value: _vm.ruleListData[index].subAmtGtFee,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleListData[index],
                                    "subAmtGtFee",
                                    $$v
                                  )
                                },
                                expression: "ruleListData[index].subAmtGtFee"
                              }
                            },
                            [
                              _c("a-radio", { attrs: { value: "1" } }, [
                                _vm._v("可以")
                              ]),
                              _c("a-radio", { attrs: { value: "2" } }, [
                                _vm._v("不可以")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "交易类型", required: "" } },
                        [
                          _c(
                            "a-checkbox-group",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isDisabledFlag == "detail"
                              },
                              model: {
                                value: _vm.ruleListData[index].payMode,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.ruleListData[index],
                                    "payMode",
                                    $$v
                                  )
                                },
                                expression: "ruleListData[index].payMode"
                              }
                            },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: "02" } },
                                        [_vm._v("微信")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: "03" } },
                                        [_vm._v("支付宝")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: "81" } },
                                        [_vm._v("银联二维码借记")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-row",
                                { staticStyle: { "margin-top": "6px" } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: "82" } },
                                        [_vm._v("银联二维码贷记")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: "00" } },
                                        [_vm._v("刷卡借记")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-checkbox",
                                        { attrs: { value: "01" } },
                                        [_vm._v("刷卡贷记")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "10%", top: "-200px", left: "4px" },
                      attrs: {
                        type: "dashed",
                        disabled:
                          _vm.isDisabledFlag == "detail" ||
                          _vm.ruleListData[index].falg != "new"
                      },
                      on: {
                        click: function() {
                          return _vm.remove(k)
                        }
                      }
                    },
                    [_vm._v("\n          删除\n      ")]
                  )
                ],
                1
              )
            })
          ],
          2
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }