












































































































import {Modal} from 'ant-design-vue';
import {Component,Inject, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import {DisableDatePicker,FixedRate,
    FixedRatio,
    FixedAmount} from '@/components/custom-form-components';

import AccountInfo from '@/components/custom-form-components/AccountInfo.vue';
import policyBank from '@/components/custom-form-components/policyBanks.vue';
import Owner from '@/components/custom-form-components/Owner.vue';
interface EditActForm {
    form: any;
    otherForm:any
}

@Component({
        components: {
            DisableDatePicker,
            FixedRate,
            FixedRatio,
            FixedAmount,
            policyBank,
            AccountInfo,
            Owner
        },
        computed: {
            ...mapState({
                loading: (state: any) => state.merSubsidyActSearchPage.loading,
            }),
            
        }
    })
    class createForm extends Vue {
        @Inject()
        public dispatch!: any;
        public rules:any;
        public fixedRateShow: boolean = true;
        public ratioListData:any;
        public fixedRatioShow: boolean = false;
        public amountListData:any;
        public fixedAmountShow: boolean = false;
        public accModeType:boolean = false;
        public createFlag = false;
        form: any;
        public data() {
            return {
                config,
                form: this.$form.createForm(this),
                rateListData: [{key:0,realSubRate:'0'}],
                rules:[],
                fixedRateShow:true,
                ratioListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',realSubRate:'0'}],
                fixedRatioShow:false,
                amountListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
                fixedAmountShow:false,
                accModeType:false
            };
        }
        public created() {
            
        }
        public showAccMode(data: any) {
            this.accModeType = data;
            // this.form.setFieldsValue({
            //     accMode: undefined,
            // });
        }
        public handleCreate(e: any) {
            e.preventDefault();
            this.form.validateFields((error: any, values: any) => {
                if (!error) {
                    // if(values.accModeType && !values.accMode){
                    //     this.$message.error('仅记账模式打开后应补头寸编号必填。');
                    //     return;
                    // }
                    const subsidyType = values.subsidyType;
                    const mustAmtPattern = /(^[1-9]{1}\d{0,5}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)|(^[0]{1}$)/;
                    if('1' == subsidyType){
                        this.rateListData= this.$refs.rateChild.rateListData;
                        this.rules = this.rateListData;
                        for(let rateData of this.rateListData){
                            if(!values.accModeType){
                                rateData.realSubRate = '0';
                            }
                            if(!mustAmtPattern.test(rateData.minAmt) || !mustAmtPattern.test(rateData.maxAmt) || parseFloat(rateData.minAmt) > 999999 || parseFloat(rateData.maxAmt) > 999999 ){
                                this.$message.error('交易金额区间值必须0-999999');
                                return;
                            }
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(rateData.rate)){
                                this.$message.error('优惠后费率0-100,且只能有两位小数');
                                return;
                            }
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(rateData.realSubRate)){
                                this.$message.error('实补比例0-100,且只能有两位小数');
                                return;
                            }
                            if(rateData.minSubsidyAmt && (!mustAmtPattern.test(rateData.minSubsidyAmt) || parseFloat(rateData.minSubsidyAmt) > 999999)){
                                this.$message.error('单笔补贴最低金额0-999999');
                                return;
                            }

                            if(rateData.maxSubsidyAmt && (!mustAmtPattern.test(rateData.maxSubsidyAmt) || parseFloat(rateData.maxSubsidyAmt) > 999999)){
                                this.$message.error('单笔补贴封顶金额0-999999');
                                return;
                            }
                        }
                        if(!this.checkSpaceAmt(this.rateListData)){
                            return;
                        }
                    }else if('2' == subsidyType){
                        this.ratioListData= this.$refs.ratioChild.ratioListData;
                        this.rules = this.ratioListData;
                        for(let ratioData of this.ratioListData){
                            if(!values.accModeType){
                                ratioData.realSubRate = '0';
                            }
                            if(!mustAmtPattern.test(ratioData.minAmt) || !mustAmtPattern.test(ratioData.maxAmt) || parseFloat(ratioData.minAmt) > 999999 || parseFloat(ratioData.maxAmt) > 999999 ){
                                this.$message.error('交易金额区间值必须0-999999');
                                return;
                            }
                            if(!/(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/.test(ratioData.ratio)){
                                this.$message.error('补贴比例只能有两位小数');
                                return;
                            }
                            if(!/^(0|100|([1-9][0-9]?)|(0|[1-9][0-9]?)(?:\.\d{0,2}))?$/.test(ratioData.realSubRate)){
                                this.$message.error('实补比例0-100,且只能有两位小数');
                                return;
                            }
                            if(ratioData.minSubsidyAmt && (!mustAmtPattern.test(ratioData.minSubsidyAmt) || parseFloat(ratioData.minSubsidyAmt) > 999999 )){
                                this.$message.error('单笔补贴最低金额0-999999');
                                return;
                            }

                            if(ratioData.maxSubsidyAmt && (!mustAmtPattern.test(ratioData.maxSubsidyAmt)|| parseFloat(ratioData.maxSubsidyAmt) > 999999 )){
                                this.$message.error('单笔补贴封顶金额0-999999');
                                return;
                            }
                        }
                        if(!this.checkSpaceAmt(this.ratioListData)){
                            return;
                        }
                    }else{
                        this.amountListData= this.$refs.amountChild.amountListData;
                        this.rules = this.amountListData;
                        for(let amountData of this.amountListData){
                            if(!mustAmtPattern.test(amountData.minAmt) || !mustAmtPattern.test(amountData.maxAmt) || parseFloat(amountData.minAmt) > 999999 || parseFloat(amountData.maxAmt) > 999999 ){
                                this.$message.error('交易金额区间值必须0-999999');
                                return;
                            }
                            if(!/(^[1-9]{1}\d{0,8}(\.\d{1,2})?$)|(^0\.[1-9]{1}\d{0,1}$)|(^0\.\d{1}[1-9]{1}$)/.test(amountData.transAmt)){
                                this.$message.error('补贴金额只能是数字且有两位小数');
                                return;
                            }
                        }
                        if(!this.checkSpaceAmt(this.amountListData)){
                            return;
                        }
                    }
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'merSubsidyActSearchPage/create',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                }
            });
        }
        @Watch('$store.state.merSubsidyActSearchPage.updatedSuccess')
        public updatedSuccess(newVal: boolean, oldVal: boolean) {
            if (newVal) {
                console.log(123);
                this.$message.success('提交成功');
                const queryString = this.$store.getters[
                    'merSubsidyActSearchPage/queryString'
                ]();
                this.$router.push({
                    path: '/console/activity/merSubsidyAct/search' + queryString,
                });
                this.dispatch('search');
            }
        }
        public checkSpaceAmt(dataList:any){
         //交易金额区间不重复校验
            for(let i=0;i<dataList.length;i++){
                const rateData = dataList[i];
                //b<a err
                const a = parseFloat(rateData.minAmt);
                const b = parseFloat(rateData.maxAmt);
                if(b < a){
                    this.$message.error('交易金额区间最大值必须小于最小值。'+a+"-"+b);
                    return false;
                }
                for(let j=i+1;j<dataList.length;j++){
                    const nextData = dataList[j];
                    //d<c err
                    const c =  parseFloat(nextData.minAmt);
                    const d = parseFloat(nextData.maxAmt);
                    console.log(c,d);
                    if( d < c){
                        this.$message.error('交易金额区间最大值必须小于最小值。'+c+"-"+d);
                        return false;
                    }
                    // a<=c <=b  ||  a<= d <= b  err
                    if((c >= a && c <= b) || (d >= a && d <= b) || (c<=a && d>= b)){
                        this.$message.error('交易金额区间重合。'+a+"-"+b+','+c+"-"+d);
                        return false;
                    }
                }
            }
            return true;
        }
        public showSubsidyType(data: any) {
            let type = data.target.value;
            if('1' == type){
                this.fixedRateShow = true;
                this.fixedRatioShow = false;
                this.fixedAmountShow = false;
            }else if('2' == type){
                this.fixedRateShow = false;
                this.fixedRatioShow = true;
                this.fixedAmountShow = false;
            }else if('3' == type){
                this.fixedRateShow = false;
                this.fixedRatioShow = false;
                this.fixedAmountShow = true;
            }
        }
        public async handleCancel() {
            Modal.confirm({
                title: '提示',
                content: '是否确认取消?',
                onOk: () => {
                     const queryString = this.$store.getters[
                        'merSubsidyActSearchPage/queryString'
                    ]();
                    this.$router.push({
                        path: '/console/activity/merSubsidyAct/search' + queryString,
                    });
                },
                onCancel() {
                    // do nothing
                }
            });
        }
        
        /**
         * 将表单数据格式化为请求参数
         */
        private formatParams(formData: any) {
            let accMode = '0';
            if(formData.accModeType){
                accMode = '1';
            }
            return {
                name: formData.name,
                ownerId: formData.ownerId,
                expenseAccount:formData.expenseAccount,
                startTime: formData.activity_time[0].format(
                    'YYYYMMDDHHmmss'
                ),
                endTime: formData.activity_time[1].format(
                    'YYYYMMDDHHmmss'
                ),
                payMode:formData.payMode,
                subsidyType:formData.subsidyType,
                rules:this.rules,
                accMode:accMode

            };
        }

        
    }

    export default createForm;
