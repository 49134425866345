
























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
interface DetailForm {
    form: any;
    otherForm:any;
}

@Component<DetailForm>({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.activityMerSearchPage.data &&
                    this.formatFormData(state.activityMerSearchPage.data)
                );
            },
            
            spinning(state) {
                return(
                    state.activityMerSearchPage.loading
                )
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    //页面tab
    public form;
    public data() {
        return {
            districtCodeList:[],
            createFlag : false,
            //第一页数据
            form: this.$form.createForm(this),
            rules:[]
        };
    }
    public created() {}

    private formatFormData(rawData: any) {
        console.log(rawData)
        return {
            ...rawData
        }
    }
}

export default DetailForm;
