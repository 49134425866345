var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ad-create-form-wrapper" } },
    [
      _c(
        "a-form",
        {
          staticClass: "mall-create-form",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleCreate }
        },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("基本信息")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _c(
            "a-form-item",
            { attrs: { label: "名称" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "name",
                      {
                        initialValue: _vm.formData.name,
                        rules: [
                          { required: true, message: "请填写必填项" },
                          { max: 64, message: "长度超限，请检查后重新输入!" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'name',\n                    {\n                        initialValue: formData.name,\n                        rules: [\n                            {required: true, message: '请填写必填项'},\n                            {max: 64, message: '长度超限，请检查后重新输入!'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: {
                  disabled: true,
                  maxLength: 64,
                  placeholder: "请输入64个字以内"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动时间" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityTime",
                      {
                        initialValue:
                          _vm.formData.startTime + "—" + _vm.formData.endTime
                      }
                    ],
                    expression:
                      "[\n                    'activityTime',\n                    {\n                        initialValue: formData.startTime +'—'+formData.endTime\n                    }\n                ]"
                  }
                ],
                attrs: { disabled: true, placeholder: "-" }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动银行" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "bank",
                      {
                        initialValue: _vm.formData.bank
                      }
                    ],
                    expression:
                      "['bank', \n                    {\n                        initialValue: formData.bank\n                    }\n                ]\n            "
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动区域" } },
            [
              _c("districtCodeTransfer", {
                attrs: {
                  disabled: true,
                  targetDistrictCodeData: _vm.formData.districtCodeList
                },
                on: { change: _vm.changeDistrictCodeData }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动商户机构" } },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "orgLimit",
                        { initialValue: _vm.formData.orgLimit }
                      ],
                      expression:
                        "['orgLimit', { initialValue: formData.orgLimit }]"
                    }
                  ],
                  attrs: { disabled: true }
                },
                [
                  _c("a-radio", { attrs: { value: "unlimited" } }, [
                    _vm._v("无限制")
                  ]),
                  _c("a-radio", { attrs: { value: "whiteList" } }, [
                    _vm._v("白名单")
                  ]),
                  _c("a-radio", { attrs: { value: "blackList" } }, [
                    _vm._v("黑名单")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.formData.orgLimit != "unlimited"
            ? _c(
                "a-form-item",
                { attrs: { label: "" } },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "orgLimitValue",
                          {
                            initialValue: _vm.formData.orgLimitValue,
                            rules: [
                              {
                                required: _vm.orgLimit != "unlimited",
                                message: "请填写必填项!"
                              },
                              {
                                pattern: /^.{0,500}$/,
                                message: "必填，限制500字"
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n                    'orgLimitValue',\n                    {  initialValue:formData.orgLimitValue,\n                        rules: [\n                            {required: orgLimit != 'unlimited', message: '请填写必填项!'},\n                            {pattern: /^.{0,500}$/, message: '必填，限制500字'}\n                        ]\n                    }\n                ]"
                      }
                    ],
                    staticStyle: { width: "80%", "margin-left": "98px" },
                    attrs: {
                      disabled: true,
                      autosize: { minRows: 2, maxRows: 6 },
                      maxlength: "500"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { label: "MCC限制" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "merMcc",
                      {
                        initialValue: _vm.formData.mcc,
                        rules: [
                          { pattern: /^.{0,300}$/, message: "必填，限制400字" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'merMcc',\n                    {initialValue: formData.mcc,\n                        rules: [\n                            {pattern: /^.{0,300}$/, message: '必填，限制400字'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: { disabled: true, autosize: { minRows: 5, maxRows: 10 } }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "头寸编号" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "expenseAccount",
                      { initialValue: _vm.formData.expenseAccount }
                    ],
                    expression:
                      "[ 'expenseAccount',{initialValue: formData.expenseAccount}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "业主方" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["ownerId", { initialValue: _vm.formData.ownerId }],
                    expression: "[ 'ownerId',{initialValue: formData.ownerId}]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动详情" } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "actDetail",
                      {
                        initialValue: _vm.formData.description,
                        rules: [
                          { required: true, message: "活动详情不能为空" },
                          { pattern: /^.{0,300}$/, message: "必填，限制400字" }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'actDetail',\n                    {   initialValue: formData.description,\n                        rules: [{ required: true, message: '活动详情不能为空' },\n                            {pattern: /^.{0,300}$/, message: '必填，限制400字'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: {
                  disabled: true,
                  placeholder: "请输入",
                  autosize: { minRows: 5, maxRows: 10 }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动图片" } },
            [
              _c("ImageUpload", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "imgUrl",
                      {
                        initialValue: _vm.imageFile,
                        rules: [{ required: true, message: "请填写必填项!" }]
                      }
                    ],
                    expression:
                      "['imgUrl', {initialValue: imageFile, rules: [{required: true, message: '请填写必填项!'}]}]"
                  }
                ],
                attrs: { disabled: true, value: _vm.imageFile }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "是否报名" } },
            [
              _c(
                "a-radio-group",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "signUp",
                        {
                          initialValue: _vm.formData.signUp,
                          rules: [
                            {
                              required: true,
                              message: "请选择必填项"
                            }
                          ]
                        }
                      ],
                      expression:
                        "[\n                    'signUp',\n                    {   initialValue: formData.signUp,\n                        rules: [\n                            {\n                                required: true,\n                                message: '请选择必填项',\n                            },\n                        ]\n                    },\n                ]"
                    }
                  ],
                  attrs: { disabled: true }
                },
                [
                  _c("a-radio", { attrs: { value: "0" } }, [_vm._v(" 否 ")]),
                  _c("a-radio", { attrs: { value: "1" } }, [_vm._v(" 是 ")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: "活动优先级" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "activityLevel",
                      {
                        initialValue: _vm.formData.activityLevel
                          ? _vm.formData.activityLevel
                          : "0",
                        rules: [
                          {
                            pattern: /^[0-9]$|^[1-5][0-9]$|60$/,
                            message: "仅支持0-60数字范围"
                          }
                        ]
                      }
                    ],
                    expression:
                      "[\n                    'activityLevel',\n                    {\n                        initialValue: formData.activityLevel?formData.activityLevel:'0',\n                        rules: [\n                            {pattern: /^[0-9]$|^[1-5][0-9]$|60$/, message: '仅支持0-60数字范围'}\n                        ]\n                    }\n                ]"
                  }
                ],
                attrs: { disabled: true }
              })
            ],
            1
          ),
          _c(
            "span",
            { staticStyle: { "font-size": "16px", "font-weight": "bold" } },
            [_vm._v("其他规则")]
          ),
          _c("a-divider", { staticStyle: { margin: "6px 0" } }),
          _vm._l(_vm.configData, function(item, index) {
            return _c(
              "span",
              [
                _c(
                  "a-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !item.disabled || _vm.validObj[item.key] == "Y",
                        expression:
                          "!item.disabled || validObj[item.key] == 'Y'"
                      }
                    ],
                    attrs: { label: item.label }
                  },
                  [
                    item.type == null
                      ? _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                item.key,
                                { initialValue: _vm.formData[item.key] }
                              ],
                              expression:
                                "[item.key ,{initialValue: formData[item.key]}]"
                            }
                          ],
                          attrs: { disabled: true }
                        })
                      : _vm._e(),
                    item.type == "radio"
                      ? _c(
                          "a-radio-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  { initialValue: _vm.formData[item.key] + "" }
                                ],
                                expression:
                                  "[item.key,{initialValue: formData[item.key]+''}]"
                              }
                            ],
                            attrs: { disabled: true }
                          },
                          _vm._l(item.options, function(option, index) {
                            return _c(
                              "span",
                              [
                                _c(
                                  "a-radio",
                                  {
                                    attrs: {
                                      value: option.value,
                                      checked: true
                                    }
                                  },
                                  [_vm._v(_vm._s(option.label))]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    item.type == "checkbox"
                      ? _c(
                          "a-checkbox-group",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  item.key,
                                  { initialValue: _vm.formData[item.key] }
                                ],
                                expression:
                                  "[item.key,{initialValue: formData[item.key]}]"
                              }
                            ],
                            attrs: { disabled: true }
                          },
                          [
                            _c(
                              "a-row",
                              _vm._l(item.options, function(option, index) {
                                return _c(
                                  "span",
                                  [
                                    _c(
                                      "a-checkbox",
                                      { attrs: { value: option.value } },
                                      [_vm._v(_vm._s(option.label) + " ")]
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    item.type == "image"
                      ? _c("ImageUpload", {
                          attrs: {
                            disabled: true,
                            value: _vm.formData[item.key]
                          }
                        })
                      : _vm._e(),
                    item.type == "textarea"
                      ? _c("a-textarea", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                item.key,
                                { initialValue: _vm.formData[item.key] }
                              ],
                              expression:
                                "[\n                        item.key,\n                        {   initialValue: formData[item.key]\n                        }\n                        ]"
                            }
                          ],
                          attrs: {
                            disabled: true,
                            autosize: { minRows: 2, maxRows: 6 },
                            maxlength: "256"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }