interface Config {
    BASE_URL: string;
    XY_BASE_URL: string;
    version: string;
    salt: string;
    EXPORT_EXCEL_TIMEOUT: number;
}

const version = '1.15.0';
const salt = 'console';
const EXPORT_EXCEL_TIMEOUT = 5 * 60 * 1000;

const DEV_CONFIG: Config = {
    // 开发环境
    version,
    // BASE_URL: 'http://10.177.86.7:31088',
    BASE_URL: 'https://mina.huijingcai.top/mgt/sit',
    XY_BASE_URL: 'http://127.0.0.1:8080',
    salt,
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};
const SIT_IP_CONFIG: Config = {
    // SIT 环境
    version,
    BASE_URL: 'http://10.177.86.7:31088',
    XY_BASE_URL: 'http://10.177.86.7:31098',
    salt,
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};
const SIT_CONFIG: Config = {
    // SIT 环境
    version,
   // BASE_URL: 'http://q.huijingcai.top:8081/pam/mgt/ad', // 广告
    // BASE_URL: 'http://10.177.86.7:30088',
    // XY_BASE_URL: 'http://10.177.89.126:30098',
    BASE_URL: 'https://mina.huijingcai.top/mgt/sit',
    XY_BASE_URL: 'https://mina.huijingcai.top/mgm/sit',
    salt,
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};

const UAT_CONFIG: Config = {
    // UAT 环境
    version,
    BASE_URL: 'http://test.huijingcai.top:8081/pam/mgt/uat',
    XY_BASE_URL: 'http://test.huijingcai.top:8081/pam/mgm/uat',
    salt,
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};
const UAT_IP_CONFIG: Config = {
    // uat 环境
    version,
    BASE_URL: 'http://10.177.140.240:30188',
    XY_BASE_URL: 'http://10.177.140.240:30198',
    salt,
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};
const PRE_CONFIG: Config = {
    // PRE 预发环境
    version,
    BASE_URL: 'https://mina.huijingcai.top/mgt',
    XY_BASE_URL: 'https://mina.huijingcai.top/mgm',
    salt: 'BATman',
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};

const PRD_CONFIG: Config = {
    // 生产环境
    version,
    BASE_URL: 'https://mina.huijingcai.cn/mgt',
    XY_BASE_URL: 'https://mina.huijingcai.cn/mgm',
    salt: 'BATman',
    EXPORT_EXCEL_TIMEOUT // 导出excel请求超时时间 300s
};

const env = process.env.VUE_APP_API || process.env.NODE_ENV;
let config: Config;
switch (env) {
    case 'development': // 开发
        config = DEV_CONFIG;
        break;
    case 'sitip':
        config = SIT_IP_CONFIG;
        break;
    case 'test': // 测试
    case 'sit':
        config = SIT_CONFIG;
        break;
    case 'uat':
        config = UAT_CONFIG;
        break;
    case 'uatip':
        config = UAT_IP_CONFIG;
        break;
    case 'pre':
        config = PRE_CONFIG;
        break;
    case 'production': // 生产
        config = PRD_CONFIG;
        break;
    default:
        config = DEV_CONFIG;
        break;
}

export default config;
