import {request_yx,downloadRequest_yx} from '@/utils';
import qs from 'querystringify';
export async function search(params: any) {

    try {
        return await request_yx({
            api: '/console/activity/bankAudit/query',
            method: 'POST',
            data: params,
            headers: {
                'Content-Type': 'application/json'
            },
        });
    } catch (e) {
        throw e;
    }
}


export async function downloadXls(params: any) {
    return await downloadRequest_yx({
        data: params,
        prefix: 'activityAudit',
        api: '/console/activity/bankAudit/file/download'
    });
}



