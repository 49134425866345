import blankCell from '@/common/blankCell';
import {
    defaultCustomRender
} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '交易日期',
        dataIndex: 'formatTxnTime',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        width: 100,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'activityName',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '规则ID',
        dataIndex: 'ruleId',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '规则名称',
        dataIndex: 'ruleName',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '头寸编号',
        dataIndex: 'account',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '商户号',
        dataIndex: 'mercNo',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '终端号',
        dataIndex: 'termNo',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '交易流水号',
        dataIndex: 'logNo',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '交易参考号',
        dataIndex: 'srefNo',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '交易金额',
        dataIndex: 'payAmt',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '交易卡类型',
        dataIndex: 'cardType',
        width: 160,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '交易类型',
        dataIndex: 'tradeType',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '仅记账',
        dataIndex: 'accMode',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '实补比例',
        dataIndex: 'realSubsidyRate',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '实补金额',
        dataIndex: 'realSubsidyAmt',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '应结补贴金额',
        dataIndex: 'shdSubsidyAmt',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '原费率',
        dataIndex: 'feeRate',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '手续费(原)',
        dataIndex: 'feeAmount',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '优惠规则',
        dataIndex: 'subsidyRate',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '补贴金额',
        dataIndex: 'subsidyAmount',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '结算状态',
        dataIndex: 'settleStatus',
        width: 100,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell
    
];
