



























































































import {Component, Vue} from 'vue-property-decorator';
import {mapState} from 'vuex';
import {deleteEmpty} from '@/utils';
import qs from 'querystringify';
import moment from 'moment';
import formRules from '@/common/formRules';

interface SearchForm {
    form: any;
}
@Component({
    components: {
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return this.formatFormData(state.activityMerSearchPage.query);
            },
            loading: (state: any) => state.activityMerSearchPage.loading
        })
    }
})
class SearchForm extends Vue {
    public data() {
        return {
            collapsed: false,
            form: this.$form.createForm(this),
            formRules
        };
    }

    public handleSearch(e: any) {
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (error) {
                return this.$message.error('请填写正确信息');
            }
            const query = deleteEmpty({
                ...this.formatQuery(values),
                page: 1,
                r: Math.random()
            });
            this.$router.push({
                path: `/console/activityMer/search?${qs.stringify(query)}`
            });

        });
    }

    public handleReset() {
        Object.keys(this.form.getFieldsValue()).forEach((key) => {
            this.form.setFieldsValue({
                [key]: undefined
            });
        });
    }

    private formatQuery(values: any){
        return {
            merchantNo: values.merchantNo,
            termNo:values.termNo,
            activityId: values.activityId?values.activityId.trim():'',
            status: values.status,
            ruleId:values.ruleId?values.ruleId.trim():'',
            templateId:values.templateId?values.templateId.trim():'',
        };
    }

    private formatFormData(query: any) {
        return {
            ...query
        };
    }
}

export default SearchForm;
