



































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {DisableDatePicker,FixedRate,
    FixedRatio,
    FixedAmount} from '@/components/custom-form-components';
import SubsidyRule from '@/components/custom-form-components/SubsidyRule.vue';
interface DetailForm {
    form: any;
    otherForm:any;
}

@Component<DetailForm>({
    components: {
        DisableDatePicker,
        FixedRate,
        FixedRatio,
        FixedAmount,
        SubsidyRule
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.merSubsidyRuleSearchPage.data &&
                    this.formatFormData(state.merSubsidyRuleSearchPage.data)
                );
            },
            
            spinning(state) {
                return(
                    state.merSubsidyRuleSearchPage.loading
                )
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    //页面tab
    public form;
    public rateListData:any;
    public rules:any;
    public fixedRateShow: boolean = true;
    public ratioListData:any;
    public fixedRatioShow: boolean = false;
    public amountListData:any;
    public ruleListData:any;
    public fixedAmountShow: boolean = false;
    public accModeType:boolean =false;
    public createFlag = 'detail';
    public flag = true;
    public data() {
        return {
            districtCodeList:[],
            //第一页数据
            form: this.$form.createForm(this),
            rateListData: [{key:0}],
            rules:[],
            fixedRateShow:true,
            ratioListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
            fixedRatioShow:false,
            amountListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
            ruleListData:[{key:0,realSubRate:'0',subAmtGtTrans: '2', subAmtGtFee: '2',subsidyType:'1'}],
            fixedAmountShow:false,
            accModeType:false
        };
    }
    public created() {}



    private formatFormData(rawData: any) {
        let template;
        let rulesTemp = rawData.rules;
        if(rawData.accMode == '1'){
            this.accModeType = true;
        }
        if(rawData.subsidyType){
            if('3' == rawData.subsidyType){
                this.amountListData = rulesTemp;
                this.fixedRateShow = false;
                this.fixedRatioShow = false;
                this.fixedAmountShow = true;
            }else if('2' == rawData.subsidyType){
                this.ratioListData = rulesTemp;
                this.fixedRateShow = false;
                this.fixedRatioShow = true;
                this.fixedAmountShow = false;
            }else{
                this.rateListData = rulesTemp;
                this.fixedRateShow = true;
                this.fixedRatioShow = false;
                this.fixedAmountShow = false;
            }
        }else{
            this.ruleListData = rulesTemp;
        }
        console.log(this.ruleListData);
        return {
            accModeType:this.accModeType,
            ...rawData
        }
    }
}

export default DetailForm;
