import {
    defaultCustomRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center'
    },
    {
        title: '费用账户',
        dataIndex: 'expenseAccount',
        width: 300,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '名称',
        dataIndex: 'name',
        width: 300,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '备注',
        dataIndex: 'remark',
        align: 'center',
        width: 600,
        customRender: defaultCustomRender
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        align: 'center',
        width: 150,
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '最后修改人',
        dataIndex: 'updateBy',
        align: 'center',
        width: 150,
        customRender: defaultCustomRender
    },
    {
        title: '最后修改时间',
        dataIndex: 'gmtModified',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        fixed: 'right',
        width: 150,
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
