import Vue from 'vue';
import Router from 'vue-router';
import Login from './pages/Login/Login.vue';
import Console from './pages/Console/index.vue';

import RouteComponent from '@/components/RouteComponent.vue';
import bizDistrictRoute from './pages/Console/BizDistrict/route';
import shopRoute from './pages/Console/Shop/route';
import shopWhiteListRoute from './pages/Console/ShopWhiteList/route';
import mallRoute from './pages/Console/Mall/route';
import orderRoute from './pages/Console/Order/route';
import accountRoute from './pages/Console/Account/route';
import homeRoute from './pages/Console/Home/route';
import marketingRoute from './pages/Console/Marketing/route';
import operateLogRoute from './pages/Console/System/OperateLog/route';
import blacklistRoute from './pages/Console/Blacklist/route';
import qWhitelistRoute from './pages/Console/QWhitelist/route';

import Advertisement from './pages/Console/Advertisement/route';
import activitiesRoute from './pages/Console/Activities/ActivityManage/route';
import activitiesHexiaoRoute from './pages/Console/Activities/Hexiao/route';
import activitiesStatementRoute from './pages/Console/Activities/Statement/route';
import ShortMessage from './pages/Console/ShortMessage/route';
import NotFound from './pages/NotFound.vue';
import Forbidden from './pages/Forbidden.vue';
import Welcome from './pages/Console/Welcome.vue'; 
import boothCodeRoute from './pages/Console/BoothCode/route';
import grayRoute from './pages/Console/Gray/route';
import payChangeRoute from './pages/Console/PayExchange/route';
import districtCodeRoute from './pages/Console/DistrictCode/route';
import shtManageRoute from './pages/Console/SHTManage/route';
import activitySignUpConfigManageRoute from './pages/Console/Activities/SignUp/config/route';
import activitySignUpInspectionManageRoute from './pages/Console/Activities/SignUp/inspection/route';
import subjectActivityManageRoute from './pages/Console/Activities/subject/route';
import accountActivityManageRoute from './pages/Console/Activities/account/route';
import policyManageRoute from './pages/Console/BankActivities/policy/route';
import actManageManageRoute from './pages/Console/BankActivities/actManage/route'; 
import actShenheManageRoute from './pages/Console/BankActivities/actShenhe/route'; 
import subsidyDetailRoute from './pages/Console/BankActivities/subsidyDetail/route'; 
import activityRuleRoute from './pages/Console/BankActivities/ruleMange/route';
import activityMerManageRoute  from './pages/Console/BankActivities/activityMer/route';
Vue.use(Router);
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/login',
            // component: Login,
        },
        {
            path: '/login',
            component: Login,
        },
        {
            path: '/console',
            name: '首页',
            component: Console,
            children: [
                {
                    path: 'welcome',
                    name: '欢迎页',
                    component: RouteComponent,
                    children: [{
                        path: '/',
                        name: '欢迎页',
                        component: Welcome,
                    }]
                },
                {
                    path: 'home',
                    name: '首页',
                    redirect: 'home/statistic-amount/search',
                    component: RouteComponent,
                    children: homeRoute,
                },
                {
                    path: 'bizDistrict',
                    redirect: 'bizDistrict/search',
                    name: '商圈管理',
                    component: RouteComponent,
                    children: bizDistrictRoute,
                },
                {
                    path: 'mall',
                    redirect: 'mall/search',
                    name: '商场管理',
                    component: RouteComponent,
                    children: mallRoute,
                },
                {
                    path: 'shop',
                    name: '商户',
                    redirect: 'shop/search',
                    component: RouteComponent,
                    children: shopRoute,
                },
                {
                    path: 'shopWhiteList',
                    name: '店铺白名单管理',
                    redirect: 'shopWhiteList/search',
                    component: RouteComponent,
                    children: shopWhiteListRoute,
                },
                {
                    path: 'order',
                    name: '订单',
                    redirect: 'order/search',
                    component: RouteComponent,
                    children: orderRoute,
                },
                {
                    path: 'account',
                    name: '用户管理',
                    redirect: 'account/user/search',
                    component: RouteComponent,
                    children: accountRoute,
                },
                {
                    path: 'marketing',
                    name: '营销数据',
                    redirect: 'marketing/newbie-data/search',
                    component: RouteComponent,
                    children: marketingRoute,
                },
                {
                    path: 'operate-log',
                    name: '操作日志',
                    redirect: 'operate-log/search',
                    component: RouteComponent,
                    children: operateLogRoute,
                },
                {
                    path: 'blacklist',
                    name: '操作日志',
                    redirect: 'blacklist/search',
                    component: RouteComponent,
                    children: blacklistRoute,
                },
                {
                    path: 'q_whitelist',
                    name: 'Q码机具白名单管理',
                    redirect: 'q_whitelist/search',
                    component: RouteComponent,
                    children: qWhitelistRoute,
                },
                {
                    path: 'advertisement',
                    name: '广告管理',
                    // redirect: 'advertisement/search',
                    component: RouteComponent,
                    children: Advertisement,
                },
                {
                    path: 'short-message',
                    name: '短信包配置管理',
                    redirect: 'short-message/search',
                    component: RouteComponent,
                    children: ShortMessage,
                },
                {
                    path: 'activity',
                    name: '营销活动管理',
                    redirect: 'activity/open/search',
                    component: RouteComponent,
                    children: activitiesRoute,
                },
                {
                    path: 'hexiao',
                    name: '核销文件上传管理',
                    redirect: 'hexiao/searchPage',
                    component: RouteComponent,
                    children: activitiesHexiaoRoute,
                },
                {
                    path: 'statement',
                    name: '对账单',
                    redirect: 'statement/operate',
                    component: RouteComponent,
                    children: activitiesStatementRoute,
                },
                {
                    path: 'boothCode',
                    name: '线下码申请下载',
                    redirect: 'boothCode/download',
                    component: RouteComponent,
                    children: boothCodeRoute,
                },
                {
                    path: 'gray',
                    name: '重构灰度切换管理',
                    redirect: 'gray/search',
                    component: RouteComponent,
                    children: grayRoute,
                },
                {
                    path: 'payexchange',
                    name: '支付转发管理',
                    redirect: 'payexchange/search',
                    component: RouteComponent,
                    children: payChangeRoute,
                },
                {
                    path: 'districtCode',
                    name: '地区码维护管理',
                    redirect: 'districtCode/search',
                    component: RouteComponent,
                    children: districtCodeRoute,
                },
                {
                    path: 'shtManage',
                    name: '商户通商户推送管理',
                    redirect: 'shtManage/search',
                    component: RouteComponent,
                    children: shtManageRoute,
                },
                {
                    path: 'config',
                    name: '报名配置管理',
                    redirect: 'signUpConfig/search',
                    component: RouteComponent,
                    children: activitySignUpConfigManageRoute,
                },
                {
                    path: 'inspection',
                    name: '报名配置管理',
                    redirect: 'signUpInspection/search',
                    component: RouteComponent,
                    children: activitySignUpInspectionManageRoute,
                },
                {
                    path: 'subject',
                    name: '主题活动管理',
                    redirect: 'subject/search',
                    component: RouteComponent,
                    children: subjectActivityManageRoute,
                },
                {
                    path: 'accountInfo',
                    name: '机构头寸管理',
                    redirect: 'accountInfo/search',
                    component: RouteComponent,
                    children: accountActivityManageRoute,
                },
                {
                    path: 'policy',
                    name: '政策管理',
                    redirect: 'policy/search',
                    component: RouteComponent,
                    children: policyManageRoute,
                },
                {
                    path: 'actManage',
                    name: '活动管理',
                    redirect: 'actManage/search',
                    component: RouteComponent,
                    children: actManageManageRoute,
                },
                {
                    path: 'actShenhe',
                    name: '报名审核管理',
                    redirect: 'actShenhe/search',
                    component: RouteComponent,
                    children: actShenheManageRoute,
                },
                {
                    path: 'subsidyDetail',
                    name: '补贴明细',
                    redirect: 'subsidyDetail/search',
                    component: RouteComponent,
                    children: subsidyDetailRoute,
                },
                {
                    path: 'bankRule',
                    name: '规则管理',
                    redirect: 'bankRule/merSubsidyRule/search',
                    component: RouteComponent,
                    children: activityRuleRoute,
                },
                {
                    path: 'activityMer',
                    name: '活动商户管理',
                    redirect: 'activityMer/search',
                    component: RouteComponent,
                    children: activityMerManageRoute,
                },
            ],
        },
        {
            path: '/forbidden',
            component: Forbidden
        },
        {
            path: '*',
            component: NotFound,
        },
    ],
    // scrollBehavior() {
    //     return { x: 0, y: 0 };
    // }
});
