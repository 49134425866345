var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", style: _vm.style },
    [
      _c("DetailDrawer", {
        attrs: { activityInfo: _vm.detailUnion, title: "查看" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("detailUnion")
          }
        }
      }),
      _c("EditActDrawer", {
        attrs: { activityInfo: _vm.editActUnion, title: "修改" },
        on: {
          close: function($event) {
            return _vm.handleDrawerClose("editActUnion")
          }
        }
      }),
      _c("a-table", {
        ref: "table",
        staticClass: "q-white-list-search-table",
        attrs: {
          size: "middle",
          rowKey: "id",
          bordered: "",
          rowSelection: _vm.rowSelection,
          columns: _vm.fields,
          dataSource: _vm.list,
          locale: {
            emptyText: _vm.loading ? _vm.loadingIcon : "无数据"
          },
          scroll: {
            x: _vm.calcTableScrollX(_vm.fields, 0),
            y: _vm.fixed || undefined
          },
          pagination: {
            total: _vm.total,
            pageSize: _vm.pageSize,
            current: _vm.page,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ["20", "50", "100"],
            showTotal: function(total) {
              return "总计 " + total + " 条记录"
            },
            onChange: _vm.handlePageChange,
            onShowSizeChange: _vm.handlePageSizeChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(value, record) {
              return [
                _c(
                  "a-button",
                  {
                    attrs: { size: "small", href: "javascript:;" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleDrawerOpenClick("detailUnion", record)
                      }
                    }
                  },
                  [_vm._v("查看")]
                ),
                _c(
                  "span",
                  { style: { paddingLeft: "5px" } },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { size: "small", href: "javascript:;" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.handleDrawerOpenClick(
                              "editActUnion",
                              record
                            )
                          }
                        }
                      },
                      [_vm._v("修改")]
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  { style: { paddingLeft: "5px" } },
                  [
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: record.status == "online",
                            expression: "record.status == 'online'"
                          }
                        ],
                        attrs: {
                          requires: [_vm.PRIVILEGE.BANK_ACTIVITY_MER.STATUS],
                          size: "small",
                          href: "javascript:;"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toInvalid(record)
                          }
                        }
                      },
                      [_vm._v("失效")]
                    )
                  ],
                  1
                ),
                _c(
                  "span",
                  { style: { paddingLeft: "5px" } },
                  [
                    _c(
                      "a-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: record.status == "offline",
                            expression: "record.status == 'offline'"
                          }
                        ],
                        attrs: {
                          requires: [_vm.PRIVILEGE.BANK_ACTIVITY_MER.STATUS],
                          size: "small",
                          href: "javascript:;"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.tovalid(record)
                          }
                        }
                      },
                      [_vm._v("生效")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }