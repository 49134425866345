



















































































































































import {Modal} from 'ant-design-vue';
import {Component,Inject, Vue,Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import config from '@/config';
import {DisableDatePicker,AwardRule,policyBank,districtCodeTransfer} from '@/components/custom-form-components';
import { findRepeatData, isRepeatArr } from '@/utils';
interface SearchForm {
    form: any;
    otherForm:any;
    hasLogo: boolean;
}

@Component({
    components: {
        DisableDatePicker,
        districtCodeTransfer,
        AwardRule,
        policyBank
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.policyActivitySearchPage.loading,
            formData(state: any) {
                return state.policyActivitySearchPage.ruleConfigData && this.formatFormData(state.policyActivitySearchPage.ruleConfigData);
            }
        }),
    }
})
class SearchForm extends Vue {
    @Inject()
    public dispatch!: any;
    public districtCodeList = [];
    public awardRuleData= [];
    public configData=[];
    public data() {
        return {
            config,
            form: this.$form.createForm(this),
            otherForm: this.$form.createForm(this),
            hasLogo: false,
            districtCodeList:[],
            configData:[],
            awardRuleData:[{Key:0}],
            validObj: {},
        };
    }
    public created() {
        
    }
    private changeDistrictCodeData(rawData: any) {
        this.districtCodeList = rawData;
    }
    public handleCreate(e: any) {
        
        e.preventDefault();
        this.form.validateFields((error: any, values: any) => {
            if (!error) {
                // if(null != this.districtCodeList && this.districtCodeList.length >5 ){
                //     this.$message.error('最多勾选5个城市');
                //     return;
                // }
                let mccs = values.merMcc;
                console.log(values.actDetail.length);
                if(values.actDetail && values.actDetail.length> 1024){
                    this.$message.error('活动描述不超过1024长度');
                    return;
                }
                if (mccs) {
                    let bins = mccs.split(',');
                    if (isRepeatArr(bins)) {
                        this.$message.error(
                            `MCC数据 "${findRepeatData(
                                bins
                            )}" 重复，请检查！`
                        );
                        return false;
                    }
                    if (mccs.length > 64) {
                        this.$message.error(
                            '不符合规则，mcc填的是4位mccCode小类，不允许填中文和1~2位大类，最多64字符长度'
                        );
                        return false;
                    }
                    var reg = /^[0-9]*$/;
                    for (let bin of bins) {
                        if (!bin) {
                            this.$message.error(
                                '请删除MCC最后的英文逗号！'
                            );
                            return false;
                        }
                        if (reg.test(bin) == false) {
                            this.$message.error(
                                `${bin}不符合规则，mcc填的是4位mccCode小类，不允许填中文和1~2位大类`
                            );
                            return false;
                        }
                        if (bin.length !=4) {
                            this.$message.error(
                                `${bin}不符合规则，mcc填的是4位mccCode小类，不允许填中文和1~2位大类`
                            );
                            return false;
                        }
                    }
                }
                let ortherFlag = false;
                this.otherForm.validateFields((error: any, values: any) => {
                    if(!error){
                        const cardbins = values.cardBinLimitValue;
                        if (values.merSettleCardBound != '0' && values.cardBinLimit != '0' && !cardbins) {
                                this.$message.error('结算卡bin限制值不能为空！');
                                return false;
                            }
                        // 指定卡bin校验
                        if (values.merSettleCardBound != '0' && values.cardBinLimit != '0' && cardbins) {
                            let bins = cardbins.split(',');
                            if (isRepeatArr(bins)) {
                                this.$message.error(
                                    `结算卡bin数据 "${findRepeatData(
                                        bins
                                    )}" 重复，请检查！`
                                );
                                return false;
                            }
                            if (cardbins.length > 500) {
                                this.$message.error(
                                    '结算卡卡BIN长度支持6位或8位数字，英文逗号分隔，最多500字符长度'
                                );
                                return false;
                            }
                            var reg = /^[0-9]*$/;
                            for (let bin of bins) {
                                if (!bin) {
                                    this.$message.error(
                                        '请删除结算卡bin最后的英文逗号！'
                                    );
                                    return false;
                                }
                                if (reg.test(bin) == false) {
                                    this.$message.error(
                                        `${bin}不符合规则，卡BIN长度支持6位或8位数字，英文逗号分隔，最多500字符长度`
                                    );
                                    return false;
                                }
                                if (bin.length != 6 && bin.length != 8) {
                                    this.$message.error(
                                        `${bin}不符合长度规则，卡BIN长度支持6位或8位数字`
                                    );
                                    return false;
                                }
                            }
                        }
                        ortherFlag = true;
                    }
                });
                if(ortherFlag){
                    Modal.confirm({
                        title: '提示',
                        content: '是否确认提交?',
                        onOk: () => {
                            this.$store.dispatch(
                                'policyActivitySearchPage/create',
                                this.formatParams(values)
                            );
                        },
                        onCancel() {
                            // do nothing
                        }
                    });
                }
            } else {
                this.$message.error('请填写正确信息');
            }
        });
    }
    @Watch('$store.state.policyActivitySearchPage.updatedSuccess')
    public updatedSuccess(newVal: boolean, oldVal: boolean) {
        if (newVal) {
            console.log(123);
            this.$message.success('提交成功');
            const queryString = this.$store.getters[
                'policyActivitySearchPage/queryString'
            ]();
            this.$router.push({
                path: '/console/policy/search' + queryString,
            });
        }
    }
    public async handleCancel() {
        Modal.confirm({
            title: '提示',
            content: '是否确认取消?',
            onOk: () => {
                const queryString = this.$store.getters['policyActivitySearchPage/queryString']();
                this.$router.push({
                    path: '/console/policy/search' + queryString
                });
            },
            onCancel() {
                // do nothing
            }
        });
    }

    /**
     * 将表单数据格式化为请求参数
     */
    private formatParams(formData: any) {
        console.log("formData",formData);
        let template:any;
        this.otherForm.validateFields((error: any, values: any) => {
            console.log("otherForm",values);
            template = {
                ...values,
                merMcc:formData.merMcc,
                actBank:formData.actBank,
                actRegionArea:this.districtCodeList,
            }
        });
        console.log(template)
        for(let i in this.configData ){
            const obj = this.configData[i];
            if('radio' == obj.type && template.hasOwnProperty(obj.key) && template[obj.key]){
                let intVal = parseInt(template[obj.key]);
                template = {
                    ...template,
                    [obj.key] : intVal
                }
            }
            if('checkbox' == obj.type && template.hasOwnProperty(obj.key) && template[obj.key]){
                let newArray=[];
                let array:any = template[obj.key];
                for(let j in array){
                    let intVal = parseInt(array[j]);
                    newArray.push(intVal);
                }
                template = {
                    ...template,
                    [obj.key] : newArray
                }
            }
            
        }
        let cardBinLimit = template.cardBinLimit;
        let cardBinLimitValue = template.cardBinLimitValue;
        if(1 == template.merSettleCardBound){
            if(0 == cardBinLimit){
                cardBinLimit = 'unlimited';
                cardBinLimitValue='';
            }else if(1 == cardBinLimit){
                cardBinLimit = 'whiteList';
            }else if(2 == cardBinLimit){
                cardBinLimit = 'blackList';
            }else{
                cardBinLimitValue = '';
            }
        }else{
            cardBinLimitValue = '';
            cardBinLimit = '';
        }  
        template.cardBinLimit = undefined;
        template.cardBinLimitValue = undefined;
        const templateJson = JSON.stringify(template);
        console.log(templateJson)
        return {
            name: formData.actName,
            templetType: formData.actType,
            startTime: formData.activity_time[0].format(
                'YYYYMMDDHHmmss'
            ),
            endTime: formData.activity_time[1].format(
                'YYYYMMDDHHmmss'
            ),
            description:formData.actDetail,
            templetJson:templateJson,
            cardBinLimit:cardBinLimit,
            cardBinLimitValue:cardBinLimitValue
        };
    }

    private formatFormData(rawData: any) {
        this.configData = rawData;
        console.log(this.configData)
        return {
            ...rawData
        }
    }

    private changeCheck(checkedValues: any, options: any) {
        if (checkedValues.length == 0) {
            for (var k=0;k<options.length;k++) {
                console.log('options.enableKeys',options[k].enableKeys)
                for (var a=0;a<options[k].enableKeys.length;a++) {
                    const validkey = options[k].enableKeys[a];
                    console.log('1111:',validkey)
                    this.otherForm.setFieldsValue({[validkey]: undefined});
                    this.validObj[validkey] = undefined
                }
            }
            return
        }
        for (var i=0;i<checkedValues.length;i++) {
            const checkVal = options.filter(item => item.value == checkedValues[i])[0]
            const enableKeys = checkVal.enableKeys
            console.log('2222:',enableKeys)
            for (var j=0;j<enableKeys.length;j++) {
                this.validObj[enableKeys[j]] = 'Y'
            }
        }
        const notchecks = options.filter(item => checkedValues.indexOf(item.value+'') == -1)
        console.log(notchecks);
        for (var k=0;k<notchecks.length;k++) {
            for (var a=0;a<notchecks[k].enableKeys.length;a++) {
                const validkey = notchecks[k].enableKeys[a];
                console.log('3333:',validkey)
                this.otherForm.setFieldsValue({[validkey]: undefined});
                this.validObj[validkey] = undefined
            }
        }
    }
    private changeRadioCheck(checkedValues: any, options: any) {
        console.log(checkedValues)
        console.log(options)
        
        const redioValue = checkedValues.target.value;
        //选中
        const checkVal = options.filter(item => item.value == redioValue)[0]
        const enableKeys = checkVal.enableKeys
        if(enableKeys){
            for (var j=0;j<enableKeys.length;j++) {
                this.validObj[enableKeys[j]] = 'Y'
                //初始化值
                for(let i in this.configData ){
                    const obj = this.configData[i];
                    if(enableKeys[j] == obj.key){
                        console.log(enableKeys[j],obj.value)
                        this.otherForm.setFieldsValue({[enableKeys[j]]:obj.value});
                        continue;
                    }
                }
            }
        }
        //没选中
        const notchecks = options.filter(item => item.value != redioValue)
        console.log(notchecks);
        for (var k=0;k<notchecks.length;k++) {
            if(notchecks[k].enableKeys){
                for (var a=0;a<notchecks[k].enableKeys.length;a++) {
                    const validkey = notchecks[k].enableKeys[a];
                    console.log('没选中的validkey',validkey);
                    if(enableKeys){
                        const aaa = enableKeys.filter(item => item == validkey)
                        if(aaa){
                            break;
                        }
                    }
                    this.otherForm.setFieldsValue({[validkey]: undefined});
                    this.validObj[validkey] = undefined;
                    //判断没选中的空间下有没有绑定数据 有则清空并隐藏
                    for(let i in this.configData ){
                        const obj = this.configData[i];
                        if(validkey == obj.key && 'radio' == obj.type){
                            for (var b=0;b<obj.options.length;b++) {
                                const enableKeysChild = obj.options[b].enableKeys;
                                if(enableKeysChild){
                                    console.log('没选中的validkeydata',obj.key);
                                    for (var c=0;c<enableKeysChild.length;c++) {
                                        const validkeyChild = enableKeysChild[c];
                                        console.log('没选中的validkeyChild',validkeyChild);
                                        console.log('validkeyChild',validkeyChild);
                                        this.validObj[validkeyChild] = undefined;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

export default SearchForm;
