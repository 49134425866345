import blankCell from '@/common/blankCell';
import {defaultCustomRender,bankActivityMerStatus} from '@/utils';
export default [
    {
        title: '序号',
        dataIndex: 'idx',
        align: 'center',
        fixed: 'left',
        width: 80
    },
    {
        title: '商户号',
        dataIndex: 'merchantNo',
        align: 'center',
        fixed: 'left',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '终端号',
        dataIndex: 'termNo',
        align: 'center',
        width: 100,
        customRender: defaultCustomRender
    },
    {
        title: '活动ID',
        dataIndex: 'activityId',
        width: 150,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '活动名称',
        dataIndex: 'activityName',
        width: 200,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '规则ID',
        dataIndex: 'ruleId',
        width: 150,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '规则名称',
        dataIndex: 'ruleName',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '补贴限额',
        dataIndex: 'limit',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '已累计补贴金额',
        dataIndex: 'totalSubsidyAmt',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '补贴总交易限额',
        dataIndex: 'payLimit',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '已累计补贴交易总金额',
        dataIndex: 'totalPaySubsidyAmt',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '补贴交易封顶笔数',
        dataIndex: 'subsidyMaxNum',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '已累计补贴笔数',
        dataIndex: 'totalSubsidyNum',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '状态',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        customRender: (status: any) => {
            return bankActivityMerStatus(status);
        }
    },
    {
        title: '更新人',
        dataIndex: 'modifiedBy',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    {
        title: '更新时间',
        dataIndex: 'gmtModified',
        width: 180,
        align: 'center',
        customRender: defaultCustomRender
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 180,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
    
];
