import {
    defaultCustomRender,templetstatusRender,timeCustomRender,templetTypeRender
} from '@/utils';
import blankCell from '@/common/blankCell';

export default [
    {
        title: '序号',
        dataIndex: 'idx',
        width: 80,
        fixed: 'left',
        align: 'center',
    },
    {
        title: 'ID',
        dataIndex: 'templetId',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '名称',
        dataIndex: 'name',
        width: 200,
        align: 'center',
        fixed: 'left',
        customRender: defaultCustomRender
    },
    {
        title: '开始时间',
        dataIndex: 'startTime',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '结束时间',
        dataIndex: 'endTime',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '类型',
        dataIndex: 'templetType',
        width: 160,
        align: 'center',
        customRender: (time: any) => {
            return templetTypeRender(time);
        }
    },
    {
        title: '状态',
        dataIndex: 'status',
        align: 'center',
        width: 100,
        customRender: (time: any) => {
            return templetstatusRender(time);
        }
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '创建时间',
        dataIndex: 'gmtCreate',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    {
        title: '修改人',
        dataIndex: 'updateBy',
        align: 'center',
        width: 200,
        customRender: defaultCustomRender
    },
    {
        title: '修改时间',
        dataIndex: 'gmtModified',
        align: 'center',
        width: 200,
        customRender: (time: any) => {
            return timeCustomRender(time);
        }
    },
    blankCell,
    {
        title: '操作',
        dataIndex: 'op',
        width: 150,
        fixed: 'right',
        align: 'center',
        scopedSlots: {customRender: 'action'}
    }
];
