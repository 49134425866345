












































































































import { Modal } from 'ant-design-vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import {DisableDatePicker,FixedRate,
    FixedRatio,
    FixedAmount} from '@/components/custom-form-components';
interface DetailForm {
    form: any;
    otherForm:any;
}

@Component<DetailForm>({
    components: {
        DisableDatePicker,
        FixedRate,
    FixedRatio,
    FixedAmount,
    },
    computed: {
        ...mapState({
            formData(state: any) {
                return (
                    state.merSubsidyActSearchPage.data &&
                    this.formatFormData(state.merSubsidyActSearchPage.data)
                );
            },
            
            spinning(state) {
                return(
                    state.merSubsidyActSearchPage.loading
                )
            }
        })
    }
})
class DetailForm extends Vue {
    public tplClass: any = null;
    public tplClassMaxNumber = 0;
    //页面tab
    public form;
    public districtCodeList = [];
    public awardRuleData= [];
    public rateListData:any;
    public rules:any;
    public fixedRateShow: boolean = true;
    public ratioListData:any;
    public fixedRatioShow: boolean = false;
    public amountListData:any;
    public fixedAmountShow: boolean = false;
    public accModeType:boolean =false;
    public createFlag = false;
    public data() {
        return {
            districtCodeList:[],
            createFlag : false,
            //第一页数据
            form: this.$form.createForm(this),
            rateListData: [{key:0}],
            rules:[],
            fixedRateShow:true,
            ratioListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
            fixedRatioShow:false,
            amountListData: [{key:0,subAmtGtTrans: '2', subAmtGtFee: '2',}],
            fixedAmountShow:false,
            accModeType:false
        };
    }
    public created() {}



    private formatFormData(rawData: any) {
        let template;
        let rulesTemp = [{key:0}];
        if(rawData.subsidyType){
            this.createFlag=true;
            rulesTemp = rawData.rules;
        }
        if(rawData.accMode == '1'){
            this.accModeType = true;
        }
        if('3' == rawData.subsidyType){
            this.amountListData = rulesTemp;
            this.fixedRateShow = false;
            this.fixedRatioShow = false;
            this.fixedAmountShow = true;
        }else if('2' == rawData.subsidyType){
            this.ratioListData = rulesTemp;
            this.fixedRateShow = false;
            this.fixedRatioShow = true;
            this.fixedAmountShow = false;
        }else{
            this.rateListData = rulesTemp;
            this.fixedRateShow = true;
            this.fixedRatioShow = false;
            this.fixedAmountShow = false;
        }
        return {
            accModeType:this.accModeType,
            ...rawData
        }
    }
}

export default DetailForm;
