import {create} from '@/common/storeFactory';
import * as services from './services';
import columns from './columns';
import {deleteEmpty} from '@/utils';
import preferences from './preferences';

export default create({
    search(query, page, pageSize) {
        return services.search({
            ...query,
            pageIndex: page,
            pageSize: pageSize
        });
    },
    columns,
    beforeSearch(context: any) {
        context.commit('setSelectedRows', []);
    }
},
{
    state: {
        activityDetailList: [],
        selectedRowKeys: [],
        error: '',
        sortFields: preferences.sortFields,
        privileges: [],
        updatingStatus: [],
        updateStatusError: null,
        id: null,
        loading: false,
        defaultConflicts: [],
        updatedSuccess:false,
        data: null,
        ruleConfigData:[],
        updated: null,
        districtCodeData:null,
        updatedUrlSuccess:null,
    },
    mutations: {
        setActivityDetailList(state: any, payload: any) {
            state.activityDetailList = payload;
        },
        setSelectedRows(state: any, rows: any) {
            state.selectedRowKeys = rows;
        },
        setError(state: any, error: any) {
            state.error = error;
        },
        setPermissionList(state: any, payload: any) {
            state.privileges = payload;
        },
        setColumns(state: any, columns: any) {
            state.columns = columns;
        },
        updateStatusRequested(state: any, id: any) {
            state.updatingStatus = [...state.updatingStatus, id];
        },
        updateStatusFailed(state: any, error: any) {
            state.updateStatusError = error;
        },

        updateStatusFinally(state: any, id: any) {
            const idx = state.updatingStatus.indexOf(id);
            if (idx >= 0) {
                state.updatingStatus.splice(idx, 1);
                state.updatingStatus = [...state.updatingStatus];
            }
        },
        init(state: any) {
            state.id = null;
            state.loading = false;
            state.error = null;
        },
        setLoading(state: any, loading: boolean) {
            state.loading = loading;
        },
        setUpdatedSuccess(state: any, updatedSuccess: any) {
            state.updatedSuccess = updatedSuccess;
        },
        created(state: any, id: string) {
            state.id = id;
        },
        setConfigData(state: any, data: any) {
            state.ruleConfigData = {
                ...data
            };
        },
        initDataRequested(state: any) {
            state.loading = true;
            state.data = null;
            state.updated = null;
        },
        initDataSuccessed(state: any, data: any) {
            state.loading = false;
            state.data = data;
        },
        initDataFailed(state: any, error: any) {
            state.loading = false;
            state.error = error;
        },
    },
    actions: {
        
        /**
         * 新增
         * @param context action上下文
         */
        async create(context: any, payload: any) {
            context.commit('setUpdatedSuccess', false);
            context.commit('setLoading', true);
            try {
                context.commit('created', await services.create({
                    ...payload
                }));
                context.commit('setUpdatedSuccess', true);
            } catch (e) {
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }
        },
        /**
         * 编辑活动
         * @param context action上下文
         */
        async editAct(context: any, payload: any) {
            context.commit('setUpdatedSuccess',false);
            context.commit('setLoading', true);
            try {
                context.commit('created', await services.editAct({
                    ...payload
                }));
                context.commit('setUpdatedSuccess',true);
            } catch (e) {
                console.info("error editAct")
                context.commit('setError', e);
            } finally {
                context.commit('setLoading', false);
            }

        },

        async updateStatus(context: any,params:any) {
            context.commit('updateStatusRequested', params);
            try {
                await services.updateStatus(params);
                context.dispatch('search');
            } catch (e) {
                context.commit('updateStatusFailed', e);
            } finally {
                context.commit('updateStatusFinally', params);
            }
        },
        async initData(context: any, id: any) {
            context.commit('initDataRequested');
            try {
                const data = await services.getInfo({...id});
                context.commit('initDataSuccessed', data);
            } catch (e) {
                context.commit('initDataFailed', e);
            }
        },
        

    }
});
