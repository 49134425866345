































import {mapState} from 'vuex';
import {Component, Watch, Inject, Vue} from 'vue-property-decorator';
import {Click} from '@/components/Auth';
import UploadDrawer from './UploadDrawer/index.vue';
import PRIVILEGE from '@/common/privileges';
import {Modal} from 'ant-design-vue';

interface Actions {
    selectedRows: any;
    hideMessage: any;
}

@Component({
    components: {
        Click,  
        UploadDrawer
    },
    computed: {
        ...mapState({
            loading: (state: any) => state.activityMerSearchPage.loading,
            downloading: (state: any) => state.activityMerSearchPage.downloading,
            selectedRows: (state: any) => state.activityMerSearchPage.selectedRows,
        })
    }
})
class Actions extends Vue {
    @Inject()
    public dispatch!: any;
    public PRIVILEGE = PRIVILEGE;
    public applyUploadType:String = 'act_mer';
    public data() {
        return {
            hideMessage: null,
            PRIVILEGE
        };
    }

    public destroyed() {
        return this.hideMessage && this.hideMessage();
    }

    public handleRefresh() {
        this.$router.push({
            path: '/console/activityMer/search' + this.$store.getters['activityMerSearchPage/queryString']()
        });
    }
    
    @Watch('$store.state.activityMerSearchPage.downloading')
    public watchDownloading(downloading: any) {
        if (!downloading && this.hideMessage) {
            this.hideMessage();
        }
    }

    @Watch('$store.state.activityMerSearchPage.downloadError')
    public watchDownloadError(error: any) {
        if (error && !error.silent) {
            this.$message.error('导出失败，请联系技术人员!');
        }
    }

    @Watch('$store.state.activityMerSearchPage.lastDownloadTime')
    public watchLastDownloadTime(lastDownloadTime: any) {
        this.$message.success('导出完成!');
    }

    public handleDownload() {
        this.hideMessage = this.$message.loading('导出中，请稍后...', 0);
        this.$store.dispatch('activityMerSearchPage/download');
    }
    public handleAddAdPlan() {
        this.$router.push('/console/activityMer/createActivityMer');
    }
    public toInvalid(){
        Modal.confirm({
            title: '提示',
            content: '确定将所选商户失效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: 'offline'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
    public tovalid(){
        Modal.confirm({
            title: '提示',
            content: '确定将所选商户生效吗？',
            onOk: () => {
                this.dispatch('updateStatus', {
                    ids: this.selectedRows.join(','),
                    status: 'online'
                });
                this.dispatch('search');
            },
            onCancel() {
                // do nothing
            }
        });
    }
}

export default Actions;
