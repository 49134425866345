









import CreateForm from './CreateForm.vue';
import {Component, Vue, Watch} from 'vue-property-decorator';
import {Show} from '@/components/Auth';
import PRIVILEGE from '@/common/privileges';

@Component({
    components: {
        Show,
        CreateForm
    }
})
export default class CreatePage extends Vue {
    public PRIVILEGE = PRIVILEGE;
    public created() {
        
    }
    @Watch('$store.state.activityMerSearchPage.created')
    public createdListener() {
        this.$message.success('提交成功');
    }

    @Watch('$store.state.activityMerSearchPage.error')
    public errorListener(error: any) {
        return error && !error.silent && this.$message.error(error.message);
    }
    
}
